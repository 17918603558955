import { axios } from "../axios";

class QRCodeService {
  constructor() {
    this.endpoint = process.env.VUE_APP_API_URL;
  }

  async getQRCode(order, campaign, orderID) {
    const params = {
      name: order,
      domain: 1,
      qr_type: campaign.qr_type == 6 ? 1 : 2, // static if simple message, otherwise dynamic
      location_enabled: false,
      attributes: {
        isVCard: false,
      },
    };
    if (params.qr_type == 1) {
      params.fields_data = campaign;
    } else {
      params.campaign = campaign;
    }
    let res = await axios.post(`${this.endpoint}/api/getQRcode`, {
      ...params,
      orderID,
    });
    if (res.data && res.data.url) {
      return res.data.url;
    } else {
      throw "beaconstac: unable to generate qr code";
    }
  }

  deleteQRCode(id) {
    return axios.post(`${this.endpoint}/api/deleteQRCode`, { id });
  }
}

export const qrCodeService = new QRCodeService();

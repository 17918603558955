import { authService } from "./auth.service";
import { EventBus, EventTypes } from "../event-bus";

class CalendlyService {
  constructor() {
    this.isLoaded = false;
    this.calendly = null;
    window.addEventListener("message", this.listener);
  }

  destroy() {
    window.removeEventListener("message", this.listener);
  }

  initialize() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        resolve(true);
      } else {
        Promise.all([this.initializeScript(), this.initializeStyle()]).then(() => {
          this.calendly = window.Calendly;
          resolve(true);
        }).catch(reject);
      }
    });
  }

  initializeScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.addEventListener("load", () => resolve(true));
      script.addEventListener("error", err => reject(err));
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      document.head.appendChild(script);
    });
  }

  initializeStyle() {
    return new Promise((resolve, reject) => {
      const style = document.createElement("link");
      style.rel = "stylesheet";
      style.type = "text/css";
      style.addEventListener("load", () => resolve(true));
      style.addEventListener("error", err => reject(err));
      style.href = "https://assets.calendly.com/assets/external/widget.css";
      document.head.appendChild(style);
    });
  }

  listener(event) {
    if (event.data.event && event.data.event.indexOf('calendly') === 0) {
      // contains event.uri and invitee.uri
      let eventType;
      switch (event.data.event) {
        case "calendly.event_scheduled":
          eventType = EventTypes.CALENDLY.EVENT_SCHEDULED;
      }
      if (eventType) {
        EventBus.$emit(eventType, event.data.payload);
      }
    }
  }

  showPopupWidget(url) {
    return new Promise((resolve, reject) => {
      if (!url) {
        reject("calendly-service: invalid url for showing calendly");
        return;
      }
      const prefill = {};
      if (authService.userData) {
        prefill.name = authService.userData.firstname;
        prefill.email = authService.userData.email;
      }
      this.initialize().then(() => {
        resolve(this.calendly.initPopupWidget({ url, prefill }));
      }).catch(reject);
    });
  }
}

export const calendlyService = new CalendlyService();
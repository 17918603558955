import Vue from "vue";
import VueRouter from "vue-router";

// import Home2 from "../views/Home2.vue";

import { authService } from "../services/auth.service";
import { EventBus, EventTypes } from "../event-bus";
import { getRoutes } from "./routes";
import { axios } from "../axios";

Vue.use(VueRouter);

const routes = getRoutes();

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    // if (to.hash) {
    //   return { selector: to.hash, offset: 60 };
    // } else {
    //   return { x: 0, y: 0 };
    // }
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ selector: to.hash, offset: { y: 60 } });
        } else {
          resolve({ x: 0, y: 0 });
        }
      })
    });
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // const tierRequired = !authService.isAuthenticated && to.matched.some((r) => r.meta && r.meta.tier);
  const unauthRequired = authService.isAuthenticated && to.matched.some((r) => r.meta && r.meta.auth === false);
  const authRequired = !authService.isAuthenticated && to.matched.some((r) => r.meta && r.meta.auth);
  const pendingUserRequired = !authService.verificationPending && to.matched.some((r) => r.meta && r.meta.pendingVerification);

  if (typeof(to.query.returnUrl) == "string") {
    sessionStorage.setItem('returnUrl', to.query.returnUrl);
  }

  if (
    unauthRequired ||
    authRequired ||
    pendingUserRequired
  ) {
    const route = { path: '/login' };
    if (authRequired) {
      route.query = { returnUrl: to.fullPath };
    }
    Vue.nextTick(() => {
      // if (authRequired) {
      //   EventBus.$emit(EventTypes.LOGIN_POPUP, true);
      // }
      if (unauthRequired) {
        EventBus.$emit(EventTypes.GLOBAL_MESSAGE, { title: "Inaccessible Page", message: "You can only view that page WITHOUT logging in!" });
      }
    });
    next(route);
  // } else if (tierRequired) {
  //   next('/tiers');
  } else if (to.query.cart && to.query.cart != localStorage.getItem('cartId')) {
    axios.post("/checkCartExists", { cartID: to.query.cart }).then(() => {
      next();
    }).catch(err => {
      console.log(err);
      const route = JSON.parse(JSON.stringify(to));
      route.query = { ...route.query, cart: localStorage.getItem('cartId') };
      next(route);
    })
  } else if (from.query.sref_id && !to.query.sref_id) {
    to.query.sref_id = from.query.sref_id;
    next(to);
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    let title = to.meta.title || "";
    if (title.length) {
      title += " | ";
    }
    document.title = title + "Geofactor.com | Location based marketing platform";

    if (from && from.meta && from.meta.richText) {
      let tags = from.meta.richText;
      if (!Array.isArray(tags)) {
        tags = [tags];
      }
      tags.forEach(tag => document.getElementsByName(tag).forEach(el => el.remove()));
    }
  });
});

export default router;

export const CustomToastPlugin = {
  install(Vue) {
    class Toast {
      constructor(vm) {
        // Assign the new properties to this instance
        Object.assign(this, { _vm: vm, _root: vm.$root });
        // Set these properties as read-only and non-enumerable
        Object.defineProperties(this, {
          _vm: () => ({
            enumerable: true,
            configurable: false,
            writable: false,
          }),
          _root: () => ({
            enumerable: true,
            configurable: false,
            writable: false,
          }),
        });
      }

      // --- Public Instance methods ---

      toast(msg, variant, callback, root, toaster) {
        const id = "toast-" + Math.random();
        const showButton = !!callback;
        const vueInstance = root ? this._vm.$root : this._vm;
        if (showButton) {
          let btnText;
          if (typeof callback == "function") {
            btnText = "Retry";
          } else if (callback == "reload") {
            btnText = "Reload";
            callback = location.reload.bind(location);
          }
          const click = () => {
            vueInstance.$bvToast.hide(id);
            callback();
          };
          const button = vueInstance.$createElement(
            "button",
            { on: { click }, class: "btn-link btn-toast" },
            btnText
          );
          msg = [
            vueInstance.$createElement("div", { class: "message" }, msg),
            button,
          ];
        }
        vueInstance.$bvToast.toast(msg, {
          variant,
          id,
          noCloseButton: true,
          autoHideDelay: showButton ? 10000 : 5000,
          toastClass: showButton ? "toast-with-button" : null,
          toaster,
        });
        if (typeof(window.smartlook) == "function") {
          window.smartlook('track', 'toast', { type: variant == 'danger' ? 'error' : variant });
        }
      }

      error(msg, callback) {
        this._vm.$toast.toast(msg, "danger", callback);
      }
      success(msg, callback) {
        this._vm.$toast.toast(msg, "success", callback);
      }
      warning(msg, callback) {
        this._vm.$toast.toast(msg, "warning", callback);
      }
      info(msg, callback) {
        this._vm.$toast.toast(msg, "info", callback);
      }

      rootError(msg, callback) {
        this._vm.$toast.toast(msg, "danger", callback, true);
      }
      rootSuccess(msg, callback) {
        this._vm.$toast.toast(msg, "success", callback, true);
      }
      rootWarning(msg, callback) {
        this._vm.$toast.toast(msg, "warning", callback, true);
      }
      rootInfo(msg, callback) {
        this._vm.$toast.toast(msg, "info", callback, true);
      }
    }
    Vue.mixin({
      beforeCreate() {
        this.toast_private = new Toast(this);
      },
    });

    if (!Object.prototype.hasOwnProperty.call(Vue.prototype, "$toast")) {
      Object.defineProperty(Vue.prototype, "$toast", {
        get() {
          return this.toast_private;
        },
      });
    }
  },
};

import { axios } from "../axios";
import { storageService } from "./storage.service";
import router from "../router/index";
import Cookies from "js-cookie";

class CartService {
  constructor() {
    this.existingPromise = null;
    this.directMailExcluded = false;
    this.cart = null;
    this.orders = [];
    this.getCart();
  }

  getCart(excludeDirectMail = false) {
    if (!this.existingPromise || this.directMailExcluded != excludeDirectMail) {
      this.existingPromise = new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/api/getUserCart`)
          .then((response) => {
            this.cart = response.data;
            if (excludeDirectMail) {
              this.filterOutDirectMail(this.cart);
            }
            this.existingPromise = null;
            resolve(response);
          })
          .catch((err) => {
            this.existingPromise = null;
            reject(err);
          });
      });
      this.directMailExcluded = excludeDirectMail;
    }
    return this.existingPromise;
  }

  filterOutDirectMail(cart) {
    cart.cartContents = cart.cartContents.filter(x => !x.servicetype || x.servicetype.id != 5);
    cart.pendingCarts = cart.pendingCarts.filter(x => !x.servicetype || x.servicetype.id != 5);
    cart.total = cart.cartContents.reduce((p, c) => p + (c.totalprice || 0), 0);
    cart.basetotal = cart.total - (cart.taxtotal || 0);
  }

  getCartCount(cartId = null) {
    if (this.cart && Array.isArray(this.cart.cartContents)) {
      return cartId ? this.getFilteredCart(cartId).length : this.cart.cartContents.length;
    } else {
      return 0;
    }
  }

  getFilteredCart(cartId) {
    return this.cart.cartContents.filter(x => x.cartID == cartId);
  }

  async getUpdatedCartCount(cartId = null) {
    if (this.existingPromise) {
      await this.existingPromise;
    }
    return this.getCartCount(cartId);
  }

  updateCartId(cartId) {
    localStorage.setItem("cartId", cartId);
  }

  generateCartId() {
    return axios.post("generateCart").then((res) => {
      this.updateCartId(res.data.cartID);
    });
  }

  findInExistingCart(cartId) {
    if (this.cart) {
      let cart = null;
      if (Array.isArray(this.cart.pendingCarts)) {
        cart = this.cart.pendingCarts.find((c) => c.cartID == cartId);
      }
      if (!cart && Array.isArray(this.cart.cartContents)) {
        cart = this.cart.cartContents.find((c) => c.cartID == cartId);
      }
      return cart;
    } else {
      return null;
    }
  }

  findCart(cartId) {
    return new Promise((resolve, reject) => {
      if (this.existingPromise) {
        this.existingPromise
          .then(() => resolve(this.findInExistingCart(cartId)))
          .catch(reject);
      } else {
        resolve(this.findInExistingCart(cartId));
      }
    });
  }

  fetchCartById(cartId) {
    return axios.post("getCartContents", { cartID: cartId }).then(res => {
      if (typeof(res.data.servicetype) == 'number' || typeof(res.data.servicetype) == 'string') {
        res.data.servicetype = { id: res.data.servicetype };
      }
      return res.data;
    });
  }

  fetchOrder(orderId, purge = false) {
    return new Promise((resolve, reject) => {
      if (!purge) {
        const found = this.orders.find(o => o.orderID == orderId);
        if (found) {
          resolve(found);
          return;
        }
      }
      axios.post("getOrderContents", { orderID: orderId }).then(res => {
        if (purge || !this.orders.find(o => o.orderID == orderId)) {
          this.orders.push(res.data);
        }
        resolve(res.data);
      }).catch(reject);
    });
  }

  stageOrder(order) {
    const foundIndex = this.orders.findIndex(o => o.orderID == order.orderID);
    if (foundIndex != -1) {
      this.orders.splice(foundIndex, 1);
    }
    this.orders.push(order);
  }

  processPendingItems(o) {
    if (o) {
      const order = {
        ...o,
        pendingSpecs:
          !o.papersize ||
          !o.papersize.id ||
          !o.papertype ||
          !o.papertype.id ||
          (o.servicetype && o.servicetype.id == 2 && !o.quantity) ||
          !o.designchoice,
        pendingDesign:
          ((!o.designchoice || o.designchoice == "customize") &&
            !o.designID) ||
          (o.designchoice != 'service' && !o.previews) ||
          !o.approvalStatus,
        pendingRoutes: o.servicetype && (o.servicetype.id == 1 || o.servicetype.id == 5) && !o.routeID && !o.audienceID,
        pendingSchedule: o.servicetype && o.servicetype.id == 1 && o.schedule,
      };
      if ((!order.servicetype || !order.servicetype.id) && order.routeID) {
        order.servicetype = {
          id: 1,
          label: "EDDM® Service",
        };
      }
      return order;
    } else {
      return {
        pendingSpecs: true,
        pendingDesign: true,
        pendingRoutes: true,
        pendingSchedule: true,
      };
    }
  }

  completeSpecs(o, hash) {
    if (o.servicetype && o.servicetype.id == 5) {
      router.push(`/dm/edit?cart=${o.cartID}&#${hash}`);
    } else if (o.productID) {
      router.push(`/products/edit?cart=${o.cartID}`);
    } else {
      router.push("/products?cart=" + o.cartID);
    }
  }
  
  completeDesign(o) {
    if (o.designchoice == 'upload' || o.designchoice == 'premium') {
      this.completeSpecs(o, 'creative');
    } else if (o.designID && o.previews) {
      router.push(
        `/design?edit=true&cart=${o.cartID}&from=${o.designID}&service=${o.servicetype.id}&size=${o.papersize.id}`
      );
    } else {
      if (o.documentID) {
        Cookies.set("documentID", o.documentID);
        router.push(
          `/design?cart=${o.cartID}&service=${o.servicetype.id}`
        );
      } else {
        if (o.folds && o.folds.simplename) {
          localStorage.setItem("foldType", o.folds.simplename);
        } else {
          localStorage.removeItem("foldType");
        }
        router.push(
          `/design?cart=${o.cartID}&size=${o.papersize.id}&service=${o.servicetype.id}`
        );
      }
    }
  }

  completeRoutes(o) {
    if (o.servicetype && o.servicetype.id == 5) {
      this.completeSpecs(o, 'audience');
    } else {
      router.push("/audience?cart=" + o.cartID);
    }
  }

  completeSchedule(o) {
    router.push("/schedule?cart=" + o.cartID);
  }

  clearCurrentSession() {
    Cookies.remove("collection_id");
    Cookies.remove("quicksave_json");
    Cookies.remove("total_res");
    Cookies.remove("totalBusiness");
    storageService.removeCollection("live_state");
  }
}

export const cartService = new CartService();

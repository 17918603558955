import { EventBus, EventTypes } from "@/event-bus.js";

class GoogleService {
  constructor() {
    this.isLoaded = false;
    this.isMapsLoaded = false;
    this.loaded = {};
    this.loading = {};
    this.oauthToken = null;
    this.scopes = ["https://www.googleapis.com/auth/drive.readonly"];
  }

  initialize() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        resolve(true);
      } else if (!window.prerender) {
        const script = document.createElement("script");
        script.addEventListener("load", () => {
          this.isLoaded = true;
          resolve(true);
        });
        script.addEventListener("error", (err) => reject(err));
        script.src = "https://apis.google.com/js/api.js";
        document.head.appendChild(script);
      }
    });
  }

  async load(api, handler) {
    await this.initialize();
    if (this.isLoaded) {
      if (this.loaded[api]) {
        // api has already been loaded
        handler();
        return true;
      } else {
        const apiHandler = () => {
          this.loaded[api] = true;
          handler();
        };
        window.gapi.load(api, apiHandler);
      }
    } else {
      throw "Unable to initialize base loader";
    }
  }

  authorize() {
    return new Promise((resolve, reject) => {
      if (this.oauthToken) {
        resolve(true);
      } else {
        this.load("auth2", () => {
          window.gapi.auth2.authorize(
            {
              client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
              scope: this.scopes[0],
              immediate: false,
            },
            (authResult) => {
              if (authResult && !authResult.error) {
                this.oauthToken = authResult.access_token;
                resolve(true);
              } else {
                reject(authResult && authResult.error);
              }
            }
          );
        }).catch((err) => {
          reject(err);
        });
      }
    });
  }

  showGoogleDrivePicker() {
    return new Promise((resolve, reject) => {
      this.authorize()
        .then(() => this.loadClient())
        .then(() => {
          this.load("picker", () => {
            if (this.oauthToken) {
              var picker = new window.google.picker.PickerBuilder()
                .addView(window.google.picker.ViewId.DOCS_IMAGES)
                .setOAuthToken(this.oauthToken)
                // .setDeveloperKey(process.env.VUE_APP_GOOGLE_API_KEY)
                .setCallback((data) => {
                  if (data.action == window.google.picker.Action.PICKED) {
                    data.docs.forEach((doc) => {
                      let count = 0;
                      const dataUris = [];
                      EventBus.$emit(EventTypes.GLOBAL_LOADER, true);
                      window.gapi.client.drive.files
                        .get({
                          fileId: doc.id,
                          alt: "media",
                        })
                        .then((response, a) => {
                          console.log(a);
                          if (response) {
                            if (response.error) {
                              EventBus.$emit(EventTypes.GLOBAL_LOADER, false);
                              reject(response.error);
                            } else {
                              dataUris.push(
                                "data:" +
                                  doc.mimeType +
                                  ";base64," +
                                  btoa(response.body)
                              );
                            }
                          }
                          if (++count == data.docs.length) {
                            EventBus.$emit(EventTypes.GLOBAL_LOADER, false);
                            resolve(dataUris);
                          }
                        }, err => {
                          EventBus.$emit(EventTypes.GLOBAL_LOADER, false);
                          reject(err);
                        });
                    });
                  } else if (
                    data.action == window.google.picker.Action.CANCEL
                  ) {
                    reject("Google Drive Picker: User cancellation.");
                  }
                })
                .build();
              picker.setVisible(true);
            }
          });
        })
        .catch(reject);
    });
  }

  loadClient() {
    return new Promise((resolve, reject) => {
      if (this.loaded["client"]) {
        resolve(true);
      } else {
        this.load("client", () => {
          window.gapi.client
            .init({
              apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
              client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
              scope: this.scopes[0],
              discoveryDocs: [
                "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
              ],
            })
            .then(resolve)
            .catch((err) => {
              this.loaded["client"] = false;
              reject(err);
            });
        });
      }
    });
  }

  loadMaps() {
    if (!this.loading["maps"]) {
      this.loading["maps"] = new Promise((resolve, reject) => {
        if (this.loaded["maps"]) {
          this.loading["maps"] = null;
          resolve(true);
        } else if (!window.prerender) {
          const script = document.createElement("script");
          script.addEventListener("load", () => {
            this.loaded["maps"] = true;
            this.loading["maps"] = null;
            resolve(true);
          });
          script.addEventListener("error", (err) => {
            this.loading["maps"] = null;
            reject(err);
          });
          script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`;
          document.head.appendChild(script);
        }
      });
    }
    return this.loading["maps"];
  }

  /**
   * Gets address for provided lng, lat array
   * @param {number[]} coords 
   * @returns 
   */
  reverseGeocode(coords) {
    // return axios.get("https://maps.googleapis.com/maps/api/geocode/json", { params: {
    //   latlng: `${coords[1]},${coords[0]}`,
    //   key: process.env.VUE_APP_GOOGLE_API_KEY
    // }});
    
    return new Promise((resolve, reject) => {
      this.loadMaps().then(() => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lng: coords[0], lat: coords[1] }}).then(resolve).catch(reject);
      }).catch(reject);
    });
  }
  
  loadCharts() {
    if (!this.loading["charts"]) {
      this.loading["charts"] = new Promise((resolve, reject) => {
        if (this.loaded["charts"]) {
          this.loading["charts"] = null;
          resolve(true);
        } else if (!window.prerender) {
          const script = document.createElement("script");
          script.addEventListener("load", () => {
            window.google.charts.load('current', {
              'packages': ['geochart'],
              // Note: Because markers require geocoding, you'll need a mapsApiKey.
              // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
              'mapsApiKey': process.env.VUE_APP_GOOGLE_API_KEY,
            }).then(() => {
              this.loaded["charts"] = true;
              this.loading["charts"] = null;
              resolve(true);
            }).catch(err => {
              this.loading["charts"] = null;
              reject(err);
            });
          });
          script.addEventListener("error", (err) => {
            this.loading["charts"] = null;
            reject(err);
          });
          script.src = 'https://www.gstatic.com/charts/loader.js';
          document.head.appendChild(script);
        }
      });
    }
    return this.loading["charts"];
  }
}

export const googleService = new GoogleService();

let webpSupport = null;

const checkWebpSupport = () => new Promise((res) => {
  if (typeof(webpSupport) == "boolean") {
    res(webpSupport);
  } else {
    const webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    webP.onload = webP.onerror = () => {
      res(webP.height === 2);
    };
  }
});

const updateSrc = async (el, value) => {
  const supported = await checkWebpSupport();
  const isImage = el.tagName == 'IMG';
  if (!isImage && !value) {
    window.getComputedStyle(el).slice(4, -1).replace(/"/g, "");
  }
  if (supported) {
    const webpImage = value.substring(0, value.lastIndexOf('.') + 1) + 'webp';
    if (isImage && value) {
      el.onerror = () => {
        if (el.src != value) {
          el.src = value;
        }
      };
      el.src = webpImage;
    } else {
      el.style.backgroundImage = `url(${webpImage})`;
    }
  } else {
    if (isImage) {
      el.src = value;
    } else {
      el.style.backgroundImage = `url(${value})`;
    }
  }
};

export const AdaptSrcDirective = {
  bind(el, binding) {
    updateSrc(el, binding.value);
  },
  componentUpdated(el, binding) {
    updateSrc(el, binding.value);
  },
};

import base from "axios";
import { authService } from "./services/auth.service";

export const axios = base.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api/`,
});

const auth_exclude = [];

axios.interceptors.request.use(req => {
  if (!req.data) {
    req.data = {};
  }
  if (!req.data.userID && authService.userId) {
    if (req.data instanceof FormData) {
      req.data.append('userID', authService.userId);
    } else {
      req.data.userID = authService.userId;
    }
  }
  if (authService.isAuthenticated && auth_exclude.indexOf(req.url) == -1) {
    if (!req.data.session_token) {
      req.data.session_token = authService.token;
    }
  }

  if (req.url && (!req.url.startsWith('http') || req.url.startsWith(process.env.VUE_APP_API_URL))) {
    req.headers['cookies'] = document.cookie;
  }

  return req;
});

axios.interceptors.response.use(res => res, err => {
  if (window.navigator.onLine && err.response) {
    if (err.response.status == 401) {
      alert("Session expired! Please log in again.");
      if (authService.isAuthenticated && err.response.config.url != (process.env.VUE_APP_API_URL + "/api/logout")) {
        authService.logout();
      } else {
        authService.clearStorage();
      }
    } else {
      err.formattedError = err.response && err.response.data && err.response.data.message;
      if (err.response.status == 500 || !err.formattedError) {
        err.formattedError = "Oops! Something went wrong...";
      }
    }
  } else {
    err.formattedError = "An error occurred while connecting to the network";
  }
  return Promise.reject(err);
});
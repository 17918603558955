class FacebookService {
  constructor() {
    this.isLoaded = false;
    this.initialize();
  }

  initialize() {
    if (!this.loading) {
      this.loading = new Promise((resolve, reject) => {
        if (this.isLoaded) {
          resolve(true);
        } else {
          if (window.fbq) {
            if (!this.fbq) {
              this.fbq = window.fbq;
            }
            this.isLoaded = true;
            resolve(true);
          } else {
            this.fbq = window.fbq = function () {
              this.fbq.callMethod ? this.fbq.callMethod.apply(this.fbq, arguments) : this.fbq.queue.push(arguments);
            }
            if (!window._fbq) window._fbq = this.fbq;
            this.fbq.push = this.fbq;
            this.fbq.loaded = !0;
            this.fbq.version = "2.0";
            this.fbq.queue = [];
            const script = document.createElement("script");
            script.addEventListener("load", () => {
              this.fbq("init", process.env.VUE_APP_FACEBOOK_PIXEL_ID);
              this.isLoaded = true;
              this.loading = null;
              resolve(true);
            });
            script.addEventListener("error", err => {
              this.loading = null;
              reject(err);
            });
            script.defer = true;
            script.src = "https://connect.facebook.net/en_US/fbevents.js";
            document.head.appendChild(script);
          }
        }
      });
    }
    return this.loading;
  }

  track(eventName = "PageView", properties = {}) {
    this.trackInternal(eventName, properties);
  }

  trackCustom(eventName, properties) {
    this.trackInternal(eventName, properties, true);
  }

  trackInternal(eventName, properties, isCustom = false) {
    try {
      if (this.isLoaded) {
        this.fbq(isCustom ? 'trackCustom' : 'track', eventName, properties);
      } else {
        this.initialize().then(() => this.trackInternal(eventName, properties, isCustom));
      }
    } catch (err) {
      console.log(err);
      console.log("facebook-service: Unable to track event to fb pixel");
    }
  }
}

// class FacebookServiceStub {
//   track() {
//     console.log('track');
//   }
// }

export const facebookService = new FacebookService();

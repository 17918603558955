<template>
  <div
    class="sidebar-container"
    :class="{ active }"
    @click="active = true"
    @mouseleave="
      showUserMenu = false;
      active = false;
    "
    @mouseenter="active = true"
  >
    <div class="sidebar sidebar-fake">
      <router-link to="/" class="sidebar-logo">
        <img
          class="logo img-fluid"
          alt="Geofactor logo"
          src="/images/favicon-dark.png"
        />
      </router-link>

      <nav class="main-menu">
        <ul>
          <li v-if="isAuthenticated">
            <a href="/dashboard" @click.prevent :class="{'router-link-active': topRoute == 'dashboard'}">
              <div class="menu-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="4 4 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z"
                  ></path>
                </svg>
              </div>
            </a>
          </li>
          <li>
            <a
              href="/predictions"
              @click.prevent
              :class="{ expand: activeSubmenu == 'predictions', 'router-link-active': topRoute == 'predictions' }"
            >
              <div class="menu-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M11 10H9v3H6v2h3v3h2v-3h3v-2h-3z"></path>
                  <path
                    d="M4 22h12c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2zM4 8h12l.002 12H4V8z"
                  ></path>
                  <path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"></path>
                </svg>
              </div>
            </a>
          </li>
          <li>
            <a
              href="/campaigns"
              @click.prevent
              :class="{ expand: activeSubmenu == 'campaigns', 'router-link-active': topRoute == 'campaigns' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.5 30">
                  <g transform="translate(-244 -316)">
                    <path
                      d="M274,346H251.5a7.522,7.522,0,0,1-7.5-7.5v-15a7.522,7.522,0,0,1,7.5-7.5H274a7.522,7.522,0,0,1,7.5,7.5v15A7.522,7.522,0,0,1,274,346Zm-22.5-26.25a3.761,3.761,0,0,0-3.75,3.75v15a3.761,3.761,0,0,0,3.75,3.75H274a3.761,3.761,0,0,0,3.75-3.75v-15a3.761,3.761,0,0,0-3.75-3.75Z"
                      fill="currentColor"
                    />
                    <path
                      d="M261.853,332.978a2.665,2.665,0,0,1-1.125-.375l-15-11.25a1.875,1.875,0,0,1,2.25-3l13.875,10.313,13.875-10.312a1.875,1.875,0,0,1,2.25,3l-15,11.25A2.664,2.664,0,0,1,261.853,332.978Z"
                      transform="translate(0.897 1.772)"
                      fill="currentColor"
                    />
                    <path
                      d="M266.353,335.353a2.665,2.665,0,0,1-1.125-.375l-7.5-5.625a1.875,1.875,0,0,1,2.25-3l7.5,5.625a2.016,2.016,0,0,1,.375,2.625A1.705,1.705,0,0,1,266.353,335.353Z"
                      transform="translate(11.397 8.772)"
                      fill="currentColor"
                    />
                    <path
                      d="M246.853,335.353a1.705,1.705,0,0,1-1.5-.75,2.016,2.016,0,0,1,.375-2.625l7.5-5.625a1.875,1.875,0,0,1,2.25,3l-7.5,5.625A2.665,2.665,0,0,1,246.853,335.353Z"
                      transform="translate(0.897 8.772)"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
            </a>
          </li>
          <li>
            <A
              href="/eddm-campaigns"
              @click.prevent
              :class="{ expand: activeSubmenu == 'eddm', 'router-link-active': topRoute == 'eddm-campaigns' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="m9 6.882-7-3.5v13.236l7 3.5 6-3 7 3.5V7.382l-7-3.5-6 3zM15 15l-6 3V9l6-3v9z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </A>
          </li>
          <li>
            <a
              href="/cdm"
              @click.prevent
              :class="{ expand: activeSubmenu == 'audience', 'router-link-active': topRoute == 'cdm' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
                  <g transform="translate(-94.031 -385)">
                    <path
                      d="M95.423,403.741a1.28,1.28,0,0,1-1.15-.718,1.413,1.413,0,0,1,.431-2.012l6.179-3.88a4.271,4.271,0,0,1,4.167-.287,11.769,11.769,0,0,0,7.041,0,4.018,4.018,0,0,1,4.023.144l6.035,4.023a1.455,1.455,0,1,1-1.581,2.443l-6.035-4.023a1.1,1.1,0,0,0-1.15,0c-2.3,1.15-7.185,1.293-9.627.144a1.366,1.366,0,0,0-1.293,0l-6.179,3.88A1.056,1.056,0,0,1,95.423,403.741Z"
                      transform="translate(0 5.259)"
                      fill="currentColor"
                    />
                    <path
                      d="M106.085,399.369a7.185,7.185,0,1,1,7.185-7.185A7.115,7.115,0,0,1,106.085,399.369Zm0-11.5a4.311,4.311,0,1,0,4.311,4.311A4.234,4.234,0,0,0,106.085,387.874Z"
                      transform="translate(2.127)"
                      fill="currentColor"
                    />
                    <path
                      d="M125.795,399.83a2.043,2.043,0,0,1-.862-.287l-4.311-2.874h-.431c-1.724,1.006-5.46,1.006-7.328.144-.144,0-.287-.144-.575,0a1.437,1.437,0,0,1-1.724-2.3,3.313,3.313,0,0,1,3.449-.287c1.006.575,3.736.431,4.742,0a3.065,3.065,0,0,1,3.3.144l4.311,2.874a1.413,1.413,0,0,1,.431,2.012A1.21,1.21,0,0,1,125.795,399.83Z"
                      transform="translate(6.988 3.774)"
                      fill="currentColor"
                    />
                    <path
                      d="M116.2,396.708a5.6,5.6,0,1,1,5.6-5.6A5.706,5.706,0,0,1,116.2,396.708Zm0-8.334a2.73,2.73,0,1,0,2.73,2.73A2.826,2.826,0,0,0,116.2,388.374Z"
                      transform="translate(7.239 0.218)"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
            </a>
          </li>
          <li>
            <a
              href="/creatives"
              @click.prevent
              :class="{ expand: activeSubmenu == 'designs', 'router-link-active': topRoute == 'creatives' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30">
                  <g transform="translate(17595.004 -2163)">
                    <path
                      d="M543.857,263.857H532.429a1.429,1.429,0,0,1,0-2.857h11.429a1.429,1.429,0,1,1,0,2.857Z"
                      transform="translate(-18125.998 1929.143)"
                      fill="currentColor"
                    />
                    <path
                      d="M547.714,263.857h-4.286a1.429,1.429,0,1,1,0-2.857h4.286a1.429,1.429,0,1,1,0,2.857Z"
                      transform="translate(-18121.432 1929.143)"
                      fill="currentColor"
                    />
                    <g transform="translate(-17595.004 2163)">
                      <path
                        d="M532.43,265.9a1.3,1.3,0,0,1-1-.429,1.137,1.137,0,0,1-.286-1.429l5-15.143a1.288,1.288,0,0,1,.857-.857l7.143-3a1.3,1.3,0,0,1,1.571.286l6,6A1.3,1.3,0,0,1,552,252.9l-3,7a1.288,1.288,0,0,1-.857.857l-15.143,5C532.716,265.759,532.573,265.9,532.43,265.9Zm6.143-15.571-4,11.857,11.857-4,2.429-5.571-4.714-4.714Z"
                        transform="translate(-530.996 -240.759)"
                        fill="currentColor"
                      />
                      <path
                        d="M533.829,260.757a1.3,1.3,0,0,1-1-.429,1.381,1.381,0,0,1,0-2l6-6a1.414,1.414,0,0,1,2,2l-6,6A1.3,1.3,0,0,1,533.829,260.757Z"
                        transform="translate(-530.394 -237.757)"
                        fill="currentColor"
                      />
                      <path
                        d="M550.471,253a1.3,1.3,0,0,1-1-.429l-8.143-8.143a1.381,1.381,0,0,1,0-2,1.381,1.381,0,0,1,2,0l8.143,8.143a1.543,1.543,0,0,1,.429,1,1.3,1.3,0,0,1-.429,1A1.543,1.543,0,0,1,550.471,253Z"
                        transform="translate(-526.9 -242)"
                        fill="currentColor"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </a>
          </li>
        </ul>
      </nav>

      <div class="user-info">
        <template v-if="isAuthenticated">
          <div class="user-icon mr-3">
            {{ name.slice(0, 1) }}
          </div>
          <div class="user-details">
            <p class="user-name">&nbsp;</p>
            <p class="user-org">&nbsp;</p>
          </div>
        </template>
        <a v-else href="#" @click.prevent="login" class="user-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path
              d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
            ></path>
          </svg>
        </a>
      </div>
    </div>
    <div class="sidebar sidebar-real">
      <router-link to="/" class="sidebar-logo">
        <img
          class="logo h-100"
          alt="Geofactor logo"
          src="/images/geofactor.png"
        />
      </router-link>

      <nav class="main-menu">
        <ul>
          <li v-if="isAuthenticated">
            <router-link to="/dashboard">
              <div class="menu-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="4 4 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M4 13h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1zm-1 7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v4zm10 0a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v7zm1-10h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1z"
                  ></path>
                </svg>
              </div>
              Dashboard
            </router-link>
          </li>
          <li>
            <router-link
              to="/predictions"
              :class="{ expand: activeSubmenu == 'predictions' }"
            >
              <div class="menu-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M11 10H9v3H6v2h3v3h2v-3h3v-2h-3z"></path>
                  <path
                    d="M4 22h12c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2zM4 8h12l.002 12H4V8z"
                  ></path>
                  <path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z"></path>
                </svg>
              </div>
              Predictions Studio
              <button type="button" class="toggle-icon" @click.stop.prevent="toggleSubmenu('predictions')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9">
                  <g transform="translate(44 -110) rotate(90)">
                    <path
                      d="M111,44a.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l6.3-6.3-6.3-6.3a.99.99,0,0,1,1.4-1.4l7,7a.967.967,0,0,1,0,1.4l-7,7A.908.908,0,0,1,111,44Z"
                      fill="#6b7280"
                    />
                  </g>
                </svg>
              </button>
            </router-link>
            <ul>
              <li>
                <router-link to="/predictions/new-prediction-model/lookalike">
                  Lookalike audience
                </router-link>
              </li>
              <li>
                <router-link to="/predictions/new-prediction-model/lead_scoring">
                  Lead scoring
                </router-link>
              </li>
              <li>
                <router-link to="/predictions/new-prediction-model/persona_assignment">
                  Persona assignment
                </router-link>
              </li>
              <li>
                <router-link to="/predictions/new-prediction-model/churn_reduction">
                  Churn reduction
                </router-link>
              </li>
              <li>
                <router-link to="/predictions/other">
                  Build custom model
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/creatives/build">
                  Engagement
                </router-link>
              </li>
              <li>
                <router-link to="/creatives/build">
                  Retention
                </router-link>
              </li> -->
            </ul>
          </li>
          <li>
            <router-link
              to="/campaigns"
              :class="{ expand: activeSubmenu == 'campaigns' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.5 30">
                  <g transform="translate(-244 -316)">
                    <path
                      d="M274,346H251.5a7.522,7.522,0,0,1-7.5-7.5v-15a7.522,7.522,0,0,1,7.5-7.5H274a7.522,7.522,0,0,1,7.5,7.5v15A7.522,7.522,0,0,1,274,346Zm-22.5-26.25a3.761,3.761,0,0,0-3.75,3.75v15a3.761,3.761,0,0,0,3.75,3.75H274a3.761,3.761,0,0,0,3.75-3.75v-15a3.761,3.761,0,0,0-3.75-3.75Z"
                      fill="currentColor"
                    />
                    <path
                      d="M261.853,332.978a2.665,2.665,0,0,1-1.125-.375l-15-11.25a1.875,1.875,0,0,1,2.25-3l13.875,10.313,13.875-10.312a1.875,1.875,0,0,1,2.25,3l-15,11.25A2.664,2.664,0,0,1,261.853,332.978Z"
                      transform="translate(0.897 1.772)"
                      fill="currentColor"
                    />
                    <path
                      d="M266.353,335.353a2.665,2.665,0,0,1-1.125-.375l-7.5-5.625a1.875,1.875,0,0,1,2.25-3l7.5,5.625a2.016,2.016,0,0,1,.375,2.625A1.705,1.705,0,0,1,266.353,335.353Z"
                      transform="translate(11.397 8.772)"
                      fill="currentColor"
                    />
                    <path
                      d="M246.853,335.353a1.705,1.705,0,0,1-1.5-.75,2.016,2.016,0,0,1,.375-2.625l7.5-5.625a1.875,1.875,0,0,1,2.25,3l-7.5,5.625A2.665,2.665,0,0,1,246.853,335.353Z"
                      transform="translate(0.897 8.772)"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
              Campaigns
              <button
                type="button"
                class="toggle-icon"
                @click.stop.prevent="toggleSubmenu('campaigns')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                >
                  <g id="icon_1_" transform="translate(44 -110) rotate(90)">
                    <path
                      id="Path_59508"
                      data-name="Path 59508"
                      d="M111,44a.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l6.3-6.3-6.3-6.3a.99.99,0,0,1,1.4-1.4l7,7a.967.967,0,0,1,0,1.4l-7,7A.908.908,0,0,1,111,44Z"
                      fill="#6b7280"
                    />
                  </g>
                </svg>
              </button>
              <button
                type="button"
                class="toggle-icon"
                @click.stop.prevent="toggleSubmenu('campaigns')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                >
                  <g id="icon_1_" transform="translate(44 -110) rotate(90)">
                    <path
                      id="Path_59508"
                      data-name="Path 59508"
                      d="M111,44a.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l6.3-6.3-6.3-6.3a.99.99,0,0,1,1.4-1.4l7,7a.967.967,0,0,1,0,1.4l-7,7A.908.908,0,0,1,111,44Z"
                      fill="#6b7280"
                    />
                  </g>
                </svg>
              </button>
            </router-link>
            <ul>
              <!-- <li>
                <router-link to="/audience">
                  ＋ EDDM<sup>&reg;</sup>
                </router-link>
              </li>
              <li>
                <router-link to="/cart">
                  &nbsp; EDDM<sup>&reg;</sup> Cart
                </router-link>
              </li> -->
              <li>
                <router-link to="/campaigns">
                  Direct Mail
                </router-link>
              </li>
              <li>
                <router-link to="/campaigns/informed-delivery">
                  Informed Delivery
                </router-link>
              </li>
            </ul>
          </li>
          <li>
            <router-link
              to="/eddm-campaigns"
              :class="{ expand: activeSubmenu == 'eddm' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path
                    d="m9 6.882-7-3.5v13.236l7 3.5 6-3 7 3.5V7.382l-7-3.5-6 3zM15 15l-6 3V9l6-3v9z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              EDDM<sup>&reg;</sup>
              <button
                type="button"
                class="toggle-icon"
                @click.stop.prevent="toggleSubmenu('eddm')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                >
                  <g id="icon_1_" transform="translate(44 -110) rotate(90)">
                    <path
                      id="Path_59508"
                      data-name="Path 59508"
                      d="M111,44a.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l6.3-6.3-6.3-6.3a.99.99,0,0,1,1.4-1.4l7,7a.967.967,0,0,1,0,1.4l-7,7A.908.908,0,0,1,111,44Z"
                      fill="#6b7280"
                    />
                  </g>
                </svg>
              </button>
            </router-link>
            <ul>
              <li>
                <router-link to="/account/saved-audiences">
                  EDDM<sup>&reg;</sup> Audiences
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/cart">
                  &nbsp; EDDM<sup>&reg;</sup> Cart
                </router-link>
              </li>
              <li>
                <router-link to="/campaigns/create-direct-mail-campaign">
                  ＋ Direct Mail
                </router-link>
              </li> -->
            </ul>
          </li>
          <li>
            <router-link
              to="/cdm"
              :class="{ expand: activeSubmenu == 'audience' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
                  <g transform="translate(-94.031 -385)">
                    <path
                      d="M95.423,403.741a1.28,1.28,0,0,1-1.15-.718,1.413,1.413,0,0,1,.431-2.012l6.179-3.88a4.271,4.271,0,0,1,4.167-.287,11.769,11.769,0,0,0,7.041,0,4.018,4.018,0,0,1,4.023.144l6.035,4.023a1.455,1.455,0,1,1-1.581,2.443l-6.035-4.023a1.1,1.1,0,0,0-1.15,0c-2.3,1.15-7.185,1.293-9.627.144a1.366,1.366,0,0,0-1.293,0l-6.179,3.88A1.056,1.056,0,0,1,95.423,403.741Z"
                      transform="translate(0 5.259)"
                      fill="currentColor"
                    />
                    <path
                      d="M106.085,399.369a7.185,7.185,0,1,1,7.185-7.185A7.115,7.115,0,0,1,106.085,399.369Zm0-11.5a4.311,4.311,0,1,0,4.311,4.311A4.234,4.234,0,0,0,106.085,387.874Z"
                      transform="translate(2.127)"
                      fill="currentColor"
                    />
                    <path
                      d="M125.795,399.83a2.043,2.043,0,0,1-.862-.287l-4.311-2.874h-.431c-1.724,1.006-5.46,1.006-7.328.144-.144,0-.287-.144-.575,0a1.437,1.437,0,0,1-1.724-2.3,3.313,3.313,0,0,1,3.449-.287c1.006.575,3.736.431,4.742,0a3.065,3.065,0,0,1,3.3.144l4.311,2.874a1.413,1.413,0,0,1,.431,2.012A1.21,1.21,0,0,1,125.795,399.83Z"
                      transform="translate(6.988 3.774)"
                      fill="currentColor"
                    />
                    <path
                      d="M116.2,396.708a5.6,5.6,0,1,1,5.6-5.6A5.706,5.706,0,0,1,116.2,396.708Zm0-8.334a2.73,2.73,0,1,0,2.73,2.73A2.826,2.826,0,0,0,116.2,388.374Z"
                      transform="translate(7.239 0.218)"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>
              Audience
              <button
                type="button"
                class="toggle-icon"
                @click.stop.prevent="toggleSubmenu('audience')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                >
                  <g id="icon_1_" transform="translate(44 -110) rotate(90)">
                    <path
                      id="Path_59508"
                      data-name="Path 59508"
                      d="M111,44a.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l6.3-6.3-6.3-6.3a.99.99,0,0,1,1.4-1.4l7,7a.967.967,0,0,1,0,1.4l-7,7A.908.908,0,0,1,111,44Z"
                      fill="#6b7280"
                    />
                  </g>
                </svg>
              </button>
            </router-link>
            <ul>
              <li>
                <router-link to="/cdm/build-audience"> Buy a list </router-link>
              </li>
              <!-- <li>
                <router-link to="/cdm/lookalikes">
                  Lookalikes
                </router-link>
              </li> -->
              <li>
                <router-link to="/cdm/upload-audience">
                  Upload List
                </router-link>
              </li>
              <!-- <li>
                <router-link to="/account/saved-audiences">
                  EDDM<sup>&reg;</sup> Audiences
                </router-link>
              </li> -->
            </ul>
          </li>
          <li>
            <router-link
              to="/creatives"
              :class="{ expand: activeSubmenu == 'designs' }"
            >
              <div class="menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30">
                  <g transform="translate(17595.004 -2163)">
                    <path
                      d="M543.857,263.857H532.429a1.429,1.429,0,0,1,0-2.857h11.429a1.429,1.429,0,1,1,0,2.857Z"
                      transform="translate(-18125.998 1929.143)"
                      fill="currentColor"
                    />
                    <path
                      d="M547.714,263.857h-4.286a1.429,1.429,0,1,1,0-2.857h4.286a1.429,1.429,0,1,1,0,2.857Z"
                      transform="translate(-18121.432 1929.143)"
                      fill="currentColor"
                    />
                    <g transform="translate(-17595.004 2163)">
                      <path
                        d="M532.43,265.9a1.3,1.3,0,0,1-1-.429,1.137,1.137,0,0,1-.286-1.429l5-15.143a1.288,1.288,0,0,1,.857-.857l7.143-3a1.3,1.3,0,0,1,1.571.286l6,6A1.3,1.3,0,0,1,552,252.9l-3,7a1.288,1.288,0,0,1-.857.857l-15.143,5C532.716,265.759,532.573,265.9,532.43,265.9Zm6.143-15.571-4,11.857,11.857-4,2.429-5.571-4.714-4.714Z"
                        transform="translate(-530.996 -240.759)"
                        fill="currentColor"
                      />
                      <path
                        d="M533.829,260.757a1.3,1.3,0,0,1-1-.429,1.381,1.381,0,0,1,0-2l6-6a1.414,1.414,0,0,1,2,2l-6,6A1.3,1.3,0,0,1,533.829,260.757Z"
                        transform="translate(-530.394 -237.757)"
                        fill="currentColor"
                      />
                      <path
                        d="M550.471,253a1.3,1.3,0,0,1-1-.429l-8.143-8.143a1.381,1.381,0,0,1,0-2,1.381,1.381,0,0,1,2,0l8.143,8.143a1.543,1.543,0,0,1,.429,1,1.3,1.3,0,0,1-.429,1A1.543,1.543,0,0,1,550.471,253Z"
                        transform="translate(-526.9 -242)"
                        fill="currentColor"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              Creatives
              <button
                type="button"
                class="toggle-icon"
                @click.stop.prevent="toggleSubmenu('designs')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                >
                  <g transform="translate(44 -110) rotate(90)">
                    <path
                      d="M111,44a.908.908,0,0,1-.7-.3.967.967,0,0,1,0-1.4l6.3-6.3-6.3-6.3a.99.99,0,0,1,1.4-1.4l7,7a.967.967,0,0,1,0,1.4l-7,7A.908.908,0,0,1,111,44Z"
                      fill="#6b7280"
                    />
                  </g>
                </svg>
              </button>
            </router-link>
            <ul>
              <li>
                <router-link to="/creatives/upload">
                  Upload Creative
                </router-link>
              </li>
              <li>
                <router-link to="/creatives/build">
                  Build Creative
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>

      <div
        ref="userInfo"
        class="user-info cursor-pointer"
        @click.prevent="userClicked"
        :class="{ active: showUserMenu }"
      >
        <template v-if="isAuthenticated">
          <div class="user-icon mr-3">
            {{ name.slice(0, 1) }}
          </div>
          <div class="user-details">
            <p class="user-name">{{ name }}</p>
            <p class="user-org">{{ company }}</p>
          </div>
        </template>
        <template v-else>
          <a href="#" class="user-icon mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path
                d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
              ></path>
            </svg>
          </a>
          <div class="user-details cursor-pointer">
            <p class="user-name">Login</p>
          </div>
        </template>

        <transition name="slide-fade">
          <div
            v-if="showUserMenu"
            class="user-menu"
            :class="{ active: showUserMenu }"
          >
            <ul>
              <!-- <li>
                <router-link to="/cart"
                  >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 33 30">
                  <g transform="translate(-2 -3)">
                    <path
                      d="M31.732,9.647A1.5,1.5,0,0,0,30.5,9H10L8.268,4.845A2.991,2.991,0,0,0,5.5,3H2V6H5.5l7.116,17.077A1.5,1.5,0,0,0,14,24H26a1.5,1.5,0,0,0,1.405-.972l4.5-12A1.5,1.5,0,0,0,31.732,9.647ZM24.96,21H15l-3.75-9H28.335Z"
                      transform="translate(0)"
                      fill="currentColor"
                    />
                    <ellipse
                      cx="2.25"
                      cy="2.25"
                      rx="2.25"
                      ry="2.25"
                      transform="translate(12.5 25.5)"
                      fill="currentColor"
                    />
                    <ellipse
                      cx="2.25"
                      cy="2.25"
                      rx="2.25"
                      ry="2.25"
                      transform="translate(23 25.5)"
                      fill="currentColor"
                    />
                  </g>
                </svg>
                EDDM<sup>&reg;</sup> Cart</router-link
                >
              </li> -->
              <li v-if="isAuthenticated">
                <a @click.prevent="openDashboard">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M19 10H5c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2zM5 6h14v2H5zm2-4h10v2H7z"
                    ></path>
                  </svg>
                  Business Apps</a
                >
              </li>
              <li>
                <router-link to="/account/active-orders">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M22 8a.76.76 0 0 0 0-.21v-.08a.77.77 0 0 0-.07-.16.35.35 0 0 0-.05-.08l-.1-.13-.08-.06-.12-.09-9-5a1 1 0 0 0-1 0l-9 5-.09.07-.11.08a.41.41 0 0 0-.07.11.39.39 0 0 0-.08.1.59.59 0 0 0-.06.14.3.3 0 0 0 0 .1A.76.76 0 0 0 2 8v8a1 1 0 0 0 .52.87l9 5a.75.75 0 0 0 .13.06h.1a1.06 1.06 0 0 0 .5 0h.1l.14-.06 9-5A1 1 0 0 0 22 16V8zm-10 3.87L5.06 8l2.76-1.52 6.83 3.9zm0-7.72L18.94 8 16.7 9.25 9.87 5.34zM4 9.7l7 3.92v5.68l-7-3.89zm9 9.6v-5.68l3-1.68V15l2-1v-3.18l2-1.11v5.7z"
                    ></path>
                  </svg>
                  Orders</router-link
                >
              </li>
              <li>
                <router-link to="/account/analytics">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M3 3v17a1 1 0 0 0 1 1h17v-2H5V3H3z"></path>
                    <path
                      d="M15.293 14.707a.999.999 0 0 0 1.414 0l5-5-1.414-1.414L16 12.586l-2.293-2.293a.999.999 0 0 0-1.414 0l-5 5 1.414 1.414L13 12.414l2.293 2.293z"
                    ></path>
                  </svg>
                  EDDM<sup>&reg;</sup> Analytics</router-link
                >
              </li>
              <!-- <li>
                <router-link to="/account/lookalike-audiences"
                  >
                <svg viewBox="0 0 24 24"><path d="M20 2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm-6 2.5a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM19 15H9v-.25C9 12.901 11.254 11 14 11s5 1.901 5 3.75V15z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path></svg>
                Lookalike Audiences</router-link
                >
              </li> -->
              <!-- <li>
                <router-link to="/account/saved-audiences"
                  >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m9 6.882-7-3.5v13.236l7 3.5 6-3 7 3.5V7.382l-7-3.5-6 3zM15 15l-6 3V9l6-3v9z"></path></svg>
                EDDM<sup>&reg;</sup> Audiences</router-link
                >
              </li> -->
              <!-- <li>
                <router-link to="/account/saved-designs"
                  >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.084 2.914c-1.178-1.179-3.234-1.179-4.412 0l-8.379 8.379a.999.999 0 0 0 0 1.414l3 3a.997.997 0 0 0 1.414 0l8.379-8.379a3.123 3.123 0 0 0-.002-4.414zm-1.412 3L12 13.586 10.414 12l7.672-7.672a1.146 1.146 0 0 1 1.586.002 1.123 1.123 0 0 1 0 1.584zM8 15c-1.265-.634-3.5 0-3.5 2 0 1.197.5 2-1.5 3 0 0 3.25 2.25 5.5 0 1.274-1.274 1.494-4-.5-5z"></path></svg>Saved Designs</router-link
                >
              </li> -->
              <li>
                <router-link to="/account/personal-details">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                      d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z"
                    ></path>
                  </svg>
                  Account Details</router-link
                >
              </li>
              <li>
                <a href="#" @click.prevent="$root.authService.logout()">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="m2 12 5 4v-3h9v-2H7V8z"></path>
                    <path
                      d="M13.001 2.999a8.938 8.938 0 0 0-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051 2.051 3.08 2.051 4.95-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z"
                    ></path>
                  </svg>
                  Logout</a
                >
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>

    <address-popup
      v-if="showAddressPopup"
      :title="addressTitle"
      v-model="showAddressPopup"
      :prefill="user"
      @saved="onAddressUpdated"
    />
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";
import { EventBus, EventTypes } from "@/event-bus.js";
import { debounce } from "lodash";

const AddressPopup = () => ({
  component: import("@/components/AddressPopup.vue"),
  loading: Loader,
  error: null,
  timeout: 10000,
});

export default {
  components: {
    AddressPopup,
  },
  data() {
    return {
      activeSubmenu: null,
      showUserMenu: false,
      active: false,
      vendastaUrl: process.env.VUE_APP_VENDASTA_URL,
      showAddressPopup: false,
      addressTitle: "",
    };
  },
  beforeDestroy() {
    document.addEventListener("click", this.onDocumentClick);
  },
  mounted() {
    document.addEventListener("click", this.onDocumentClick);
  },
  methods: {
    toggleSubmenu(submenu) {
      this.active = true;
      if (this.activeSubmenu == submenu) {
        this.activeSubmenu = null;
      } else {
        this.activeSubmenu = submenu;
      }
    },
    login() {
      EventBus.$emit(EventTypes.LOGIN_POPUP, true);
    },
    userClicked() {
      if (this.isAuthenticated) {
        this.toggleUserMenu();
      } else {
        this.login();
      }
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    onDocumentClick(e) {
      const target = e.target || e.srcElement;
      if (!this.$refs.userInfo || !this.$refs.userInfo.contains(target)) {
        this.showUserMenu = false;
      }

      if (!this.$el || !this.$el.contains(target)) {
        this.active = false;
      }
    },
    openDashboard() {
      if (this.isAuthenticated && this.user) {
        if (
          this.user.isAddressValid &&
          this.user.company &&
          this.user.address
        ) {
          window.open(this.vendastaUrl, "_blank");
        } else {
          if (
            this.user.company &&
            this.user.address &&
            !this.user.isAddressValid
          ) {
            this.addressTitle =
              "We need a valid address to set up the Dashboard";
          } else {
            this.addressTitle =
              "We need the following details to enable all the dashboard features";
          }
          this.showAddressPopup = true;
        }
      }
    },
    onAddressUpdated() {
      EventBus.$emit(EventTypes.GLOBAL_LOADER, true);
      this.$root.authService
        .fetchUserDetails()
        .then(() => {
          this.showAddressPopup = false;
          this.openDashboard();
          EventBus.$emit(EventTypes.GLOBAL_LOADER, false);
        })
        .catch((err) => {
          console.log(err);
          EventBus.$emit(EventTypes.GLOBAL_LOADER, false);
        });
    },
  },
  computed: {
    isAuthenticated() {
      return this.$root.authService.isAuthenticated;
    },
    name() {
      if (this.$root.authService.userData) {
        let data = this.$root.authService.userData.firstname;
        if (this.$root.authService.userData.lastname) {
          data += " " + this.$root.authService.userData.lastname;
        }
        return data;
      } else {
        return "";
      }
    },
    company() {
      if (this.$root.authService.userData) {
        return this.$root.authService.userData.company;
      } else {
        return "";
      }
    },
    user() {
      return this.$root.authService.userData;
    },
    topRoute() {
      return (this.$route.fullPath || '').toString().split('/').filter(x => !!x)[0] || '';
    },
  },
  watch: {
    "$route.fullPath": debounce(function () {
      // this.$el.blur();
      // const main = document.querySelector('.main-view');
      // main.focus();
      this.$nextTick(() => {
        this.active = false;
      });
    }, 100),
  },
};
</script>
<style scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  top: 0;
  position: sticky;
  height: 100vh;
  height: 100dvh;
  background-color: #f9f9fb;
  border-right: 1px solid #e8e8e8;
  color: #6b7280;
  flex: 0 0 auto;
  transition: width 0.25s ease-in-out;
  font-size: 0.8em;
  z-index: 1;
}

.sidebar {
  width: 4.8rem;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-real {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
}

.sidebar-container.active .sidebar-real {
  width: 18rem;
  opacity: 1;
  z-index: 1000;
}

.sidebar .sidebar-logo {
  border-bottom: 1px solid #e4e5e9;
  height: 7.8em;
  width: 100%;
  padding: 2.5em;
  flex: 0 0 auto;
}

.sidebar-fake .sidebar-logo {
  padding: 2em 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar .user-info {
  flex: 0 0 auto;
  margin-top: auto;
  display: flex;
  padding: 1em 1.5em;
  border-top: 1px solid #e4e5e9;
  align-items: center;
  width: 100%;
  user-select: none;
  position: relative;
}

.sidebar .user-info.active {
  background-color: white;
}

.sidebar .user-info .user-icon {
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: var(--color-primary-dark);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  font-size: 2em;
  line-height: 1;
  padding-top: 0.2em;
  flex: 0 0 auto;
  text-transform: uppercase;
  overflow: hidden;
}

.sidebar .user-details {
  min-width: 0;
}

.sidebar .user-info .user-name {
  font-size: 1.375em;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar .user-info .user-org {
  font-size: 1.375em;
  color: gray;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .sidebar .main-menu {
  padding: 2.5em 1.875em;
} */

.sidebar .main-menu {
  padding: 1em;
  flex: 1 1 0px;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; 
}

.sidebar .main-menu::-webkit-scrollbar {
  display: none;
}

.sidebar .main-menu ul {
  list-style: none;
  padding-left: 0;
}

.sidebar .main-menu > ul > li {
  margin-bottom: 2em;
}

.sidebar .main-menu > ul > li > a {
  font-weight: 700;
  display: flex;
  align-items: center;
  font-size: 1.5em;
  padding: 0.6em 0.3em;
  line-height: 1.25;
  border-radius: 0.416em;
  position: relative;
}

/* .sidebar .main-menu > ul > li > a.router-link-active .menu-icon {
  background: var(--color-primary-dark);
} */

.sidebar-fake .main-menu .menu-icon {
  margin-right: 0;
}

.sidebar .main-menu .menu-icon {
  display: flex;
  width: 2em;
  height: 1.25em;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  flex: 0 0 auto;
  margin-right: 0.5em;
  justify-content: center;
}

.sidebar .main-menu .menu-icon svg {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.sidebar .main-menu > ul > li > a.router-link-active .menu-icon svg {
  color: var(--color-primary);
}

.sidebar .main-menu .toggle-icon {
  margin-left: auto;
  background: transparent;
  background: rgba(255, 255, 255, 0.15);
  /* width: 0.666em; */
  border: 0;
  /* padding: 0.5em; */
  transition: 0.2s all ease-in-out;
  padding: 0;
  flex: 0 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0.6em;
}

.sidebar .main-menu a.expand .toggle-icon {
  transform: rotate(180deg);
}

.sidebar .main-menu .toggle-icon svg {
  height: 0.375em;
}

.sidebar .main-menu > ul > li li {
  position: relative;
}

.sidebar .main-menu > ul > li li > a {
  font-size: 1.375em;
  padding: 0.454em 0.909em;
  width: 100%;
  padding-left: 3.6em;
}

/* .sidebar .main-menu > ul > li li > a::before {
  content: '';
  position: absolute;
  right: 100%;
  bottom: 50%;
  width: 1px;
  height: 100%;
  background-color: currentColor;
}

.sidebar .main-menu > ul > li li > a::after {
  content: '';
  position: absolute;
  left: 0;
  width: 1.5em;
  top: 50%;
  height: 1px;
  background-color: currentColor;
} */

.sidebar .main-menu a.router-link-active {
  background-color: #f2f2f6;
}

.sidebar .main-menu > ul > li > ul {
  max-height: 0;
  overflow: hidden;
  transition: 0.25s all ease-out;
  padding-top: 0;
}

.sidebar .main-menu > ul > li > a.expand + ul {
  max-height: 16.4em;
  padding-top: 0.9375em;
}

.user-info .user-menu {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  padding: 1em;
}

.user-info .user-menu.active {
  display: block;
}

.user-info .user-menu ul {
  list-style: none;
  border-radius: 0.625em;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e4e5e9;
  padding: 0;
  margin: 0;
  position: relative;
}

.user-info .user-menu ul::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 1em solid transparent;
  border-top-color: #ccc;
}

.user-info .user-menu ul li:not(:last-child) {
  border-bottom: 1px solid #e4e5e9;
}

.user-info .user-menu ul li a {
  padding: 1em;
  font-size: 1.25em;
  display: block;
  width: 100%;
}

.user-info .user-menu ul li a.router-link-active {
  color: var(--color-primary);
}

.user-info .user-menu ul li a:hover {
  background-color: var(--color-low-purple);
  font-weight: 700;
}

.user-info .user-menu ul li a svg {
  fill: currentColor;
  height: 1.5em;
  width: auto;
  max-width: 2em;
  margin-right: 0.5em;
}
</style>
<style>
body {
  --sidebar-width: 4.8rem;
  --real-sidebar-width: 18rem;
}
</style>

export class LSStorageProvider {
  constructor(dbName, schema) {
    this.dbName = dbName;
    this.initializePromise = this.initialize(dbName, schema);
  }

  initialize(dbName, schema) {
    return new Promise((resolve, reject) => {
      let dbText;
      try {
        dbText = localStorage.getItem(dbName);
      } catch (e) {
        reject(e);
      }
      if (dbText) {
        let db;
        try {
          db = JSON.parse(dbText);
        } catch {
          console.log('storage-provider: malformed db');
          db = {};
        }
        this.db = db;
      } else {
        this.db = {};
      }
      try {
        schema.stores.forEach(store => {
          this.createStore(store.name, store.key);
        });
      } catch (e) {
        reject(e);
      }
      resolve(true);
    });
  }

  createStore(name, key) {
    if (typeof(this.db[name]) != "object" || typeof(this.db[name].data) != "object" || typeof(this.db[name].properties) != "object") {
      this.db[name] = {
        data: {},
        properties: {
          key
        }
      };
      this.flush();
    }
  }

  getStore(name) {
    if (!(name in this.db)) {
      throw `storage-provider: store does not exist ${name}`;
    }
    return this.db[name];
  }

  insert(store, item) {
    return new Promise((resolve, reject) => {
      try {
        const objectStore = this.getStore(store);
        objectStore.data[item[objectStore.properties.key]] = { item, last_updated: new Date().getTime() };
        this.flush();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetch(store, key, fresh_timeout) {
    return new Promise((resolve, reject) => {
      try {
        const objectStore = this.getStore(store);
        const row = objectStore.data[key];
        if (row && fresh_timeout > 0 && new Date().getTime() - row.last_updated <= fresh_timeout) {
          resolve(row.item);
        } else {
          this.delete(store, key).then(() => resolve(null)).catch(reject);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  delete(store, key) {
    return new Promise((resolve, reject) => {
      try {
        const objectStore = this.getStore(store);
        if (key in objectStore.data) delete objectStore.data[key];
        this.flush();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  flush() {
    localStorage.setItem(this.dbName, JSON.stringify(this.db));
  }

  deleteDatabase() {
    return new Promise((resolve, reject) => {
      try {
        localStorage.removeItem(this.dbName);
        this.db = {};
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }
}
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Loader from "@/components/Loader.vue"
import LazyBg from "@/components/LazyBg.vue"

import { googleService } from './services/google.service'
import { authService } from './services/auth.service'
import { cartService } from './services/cart.service'
import { storageService } from './services/storage.service'
import { calendlyService } from './services/calendly.service'
import { facebookService } from './services/facebook.service'
import { yotpoService } from '@/services/yotpo.service'
import { qrCodeService } from './services/qrcode.service'
import { analyticsService } from './services/analytics.service'
import { vendastaService } from './services/vendasta.service'
import { axios } from './axios'
import { GooglePlaceDirective } from './directives/google-place'
import { AdaptSrcDirective } from './directives/adapt-src'
import { ImgOrientationDirective } from './directives/img-orientation'

import { ModalPlugin, ToastPlugin, ProgressPlugin } from 'bootstrap-vue'
import { CustomToastPlugin } from './toast.js';
import Vue2Filters from 'vue2-filters'
// import VCalendar from 'v-calendar'
import VueMask from 'v-mask'
import VueMoment from 'vue-moment';
import { VueMasonryPlugin } from 'vue-masonry';
import VueInputAutowidth from "vue-input-autowidth";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/styles.css'
import './assets/css/new-styles.css'
import './assets/css/theme.css'

Vue.prototype.$googleService = googleService;
Vue.prototype.$axios = axios;

// Vue.use(DropdownPlugin)
Vue.use(ProgressPlugin)
Vue.use(ModalPlugin)
Vue.use(ToastPlugin, {
  BToast: {
    toaster: 'b-toaster-bottom-center'
  }
})
Vue.use(CustomToastPlugin)
// Vue.use(VCalendar, {
//   masks: {
//     input: ["WWW, MMM D, YYYY", "YYYY-MM-DD", "YYYY/MM/DD"]
//   }
// })
Vue.use(VueMask)
Vue.use(Vue2Filters)
Vue.use(VueMoment)
Vue.use(VueMasonryPlugin)
Vue.use(VueInputAutowidth)

Vue.config.productionTip = false

const customRangeListener = function () {
  let value = Number(this.value);
  if (this.min != 0 || this.max != 100) {
    value = (value - this.min) * 100 / (this.max - this.min);
  }
  this.style.backgroundImage = 'linear-gradient(to right, transparent 0%, transparent ' + value + '%, #CFCFCF ' + value + '%, #CFCFCF 100%)';
}
Vue.directive("custom-range", {
  bind(el) {
    customRangeListener.apply(el);
  },
  inserted(el) {
    el.addEventListener("input", customRangeListener);
  },
  componentUpdated(el) {
    customRangeListener.apply(el);
  },
  unbind(el) {
    el.removeEventListener("input", customRangeListener);
  }
})
Vue.directive("google-place", GooglePlaceDirective)
Vue.directive("adapt-src", AdaptSrcDirective)
Vue.directive("img-orient", ImgOrientationDirective)

Vue.component("loader", Loader)
Vue.component("lazy-bg", LazyBg, { name: "lazy-bg" })

// window.location.origin is undefined for localhost in IE
if (!window.location.origin) {
  window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "");
}

new Vue({
  router,
  render: h => h(App),
  data() {
    return {
      authService,
      cartService,
      storageService,
      calendlyService,
      facebookService,
      yotpoService,
      qrCodeService,
      analyticsService,
      vendastaService,
    };
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  }
}).$mount('#app')

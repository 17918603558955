import { IDBStorageProvider } from "./storage-provider-idb.service";
import { LSStorageProvider } from "./storage-provider-ls.service";

class StorageService {
  constructor() {
    // cache items for only a day
    this.FRESH_TIMEOUT = 3 * 24 * 60 * 60 * 1000;
    this.schema = {
      stores: [
        {
          name: 'routes',
          key: 'routeID'
        }
      ]
    };
    this.initialize();
  }

  initialize() {
    if ('indexedDB' in window) {
      this.provider = new IDBStorageProvider(process.env.VUE_APP_STORAGE_DB_NAME, this.schema, process.env.VUE_APP_STORAGE_DB_VERION);
      this.provider.initializePromise.catch(e => {
        console.log(e);
        // use localStorage as backup provider
        this.provider = new LSStorageProvider(process.env.VUE_APP_STORAGE_DB_NAME, this.schema);
      })
    } else {
      this.provider = new LSStorageProvider(process.env.VUE_APP_STORAGE_DB_NAME, this.schema);
    }
    return this.provider.initializePromise;
  }

  addCollection(routes, isRetry = false) {
    return this.provider.insert('routes', routes)
      .catch(err => {
        console.log(err);
        console.log('storage-service: Error encountered during insert!');
        if (!isRetry) {
          console.log('storage-service: Recreating...');
          return this.provider.deleteDatabase().then(() => this.initialize())
            .then(() => this.addCollection(routes, true));
        }
      });
  }

  getCollection(routeID) {
    return this.provider.fetch('routes', routeID, this.FRESH_TIMEOUT);
  }

  removeCollection(routeID) {
    return this.provider.delete('routes', routeID);
  }
}

export const storageService = new StorageService();

import Cookies from "js-cookie";
import { EventBus, EventTypes } from "./event-bus";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
// import LZUTF8 from "lzutf8";

function getElementY(element) {
  return window.pageYOffset + element.getBoundingClientRect().top;
}

/* https://stackoverflow.com/questions/17722497/scroll-smoothly-to-specific-element-on-page */
export function smoothScrollTo(element, offsetY = -20, useScrollIntoView = false, duration = 1000) {
  if (typeof(element) == 'string') {
    element = document.querySelector(element);
  }
  if (!element) {
    return;
  }
  if (useScrollIntoView) {
    element.scrollIntoView({ behavior: "smooth" });
    return;
  }
  var startingY = window.pageYOffset;
  var elementY = getElementY(element) + offsetY;
  // If element is close to page's bottom then window will scroll only to some position above the element.
  var targetY =
    document.body.scrollHeight - elementY < window.innerHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementY;
  var diff = targetY - startingY;
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  var easing = function(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
  };
  var start;

  if (!diff) return;

  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed miliseconds since start of scrolling.
    var time = timestamp - start;
    // Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1);
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent);

    window.scrollTo(0, startingY + diff * percent);

    // Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step);
    }
  });
}

export function getFlowStatus() {
  return {
    areRoutesSelected: Cookies.get("collection_id") && Cookies.get("total_res"),
    isDesignDone: !!Cookies.get("quicksave_json"),
  };
}

export function nextFlowRoute(skipDesign = false, isPrint = false, service = 1, cartId = null) {
  const isDirectMail = service == 5;
  if (!cartId) {
    cartId = this.$route.query.cart || localStorage.getItem('cartId');
  }
  let audienceMsg = false;
  let url = '/campaigns/' + cartId, replace = false;
  // if (this.$route.query.edit || (this.$route.path == '/audience' && this.$route.query.from && this.$route.query.cart)) {
  //   this.$router.push('/cart');
  // } else {
    const currentCart = this.$root.cartService.findInExistingCart(cartId);
    let status;
    if (currentCart) {
      status = {
        areRoutesSelected: !!currentCart.routeID || !!currentCart.audienceID || this.$route.path == '/audience' || this.$route.path == '/audience-pro',
        isDesignDone: (currentCart.designchoice && currentCart.designchoice != 'customize') || (currentCart.designID && (currentCart.preview || currentCart.previews)) || this.$route.path == '/design',
        isScheduled: isDirectMail || currentCart.schedule || this.$route.path == '/schedule'
      };
    } else {
      status = getFlowStatus();
    }
    if (isPrint || status.areRoutesSelected) {
      if (skipDesign || status.isDesignDone) {
        if (skipDesign || isPrint || status.isScheduled || isDirectMail) {
          this.$root.cartService.generateCartId();
          this.$root.cartService.clearCurrentSession();
          if (this.$route.path == '/design') {
            replace = true;
          } else {
            if (isDirectMail) {
              url = '/campaigns/' + cartId + '?tab=creatives';
            } else {
              url = '/campaigns/' + cartId;
            }
          }
        } else {
          url = '/schedule?cart=' + cartId;
        }
      } else {
        audienceMsg = true;
        url = '/products?cart=' + cartId;
      }
    } else {
      if (isDirectMail) {
        // url = '/dm/edit/audience?cart=' + cartId;
        url = '/campaigns/' + cartId + '?tab=creatives';
      } else {
        url = '/audience?cart=' + cartId;
      }
    }
  // }

  if (this.$root.authService.isAuthenticated) {
    if (replace) {
      this.$router.replace(url);
    } else {
      this.$router.push(url);
    }
  } else {
    this.$router.push({ path: '/register', query: { returnUrl: url, audienceMsg, service } });
  }
}

export function loginSuccessHandler(res, choice) {
  EventBus.$emit(EventTypes.AUTH_CHANGED, true);
  if (choice) {
    // this.$router.replace("/cart");
    this.$router.replace('/dashboard');
  } else {
    const returnUrl = this.$route.query.returnUrl || sessionStorage.getItem('returnUrl');
    if (this.$route.path == '/login' || this.$route.meta.auth === false || this.$route.meta.pendingVerification === true) {
      if (returnUrl) {
        this.$router.push(returnUrl);
      } else if ((typeof(choice) == "undefined") && res.data.cartContents && res.data.cartContents.length > 0) {
        // this.$router.push("/cart?tab=ready");
        this.$router.replace('/dashboard');
      } else if (res.data.pendingCarts && res.data.pendingCarts.length > 0) {
        if (res.data.pendingCarts.length == 1) {
          const order = this.$root.cartService.processPendingItems(res.data.pendingCarts[0]);
          if (order.pendingRoutes) {
            this.$root.cartService.completeRoutes(order);
          } else if (order.pendingSpecs) {
            this.$root.cartService.completeSpecs(order);
          } else if (order.pendingDesign) {
            this.$root.cartService.completeDesign(order);
          } else {
            this.$root.cartService.completeSchedule(order);
          }
        } else {
          // this.$router.push("/cart?tab=pending");
          this.$router.replace('/dashboard');
        }
      } else {
        this.$router.push("/");
      }
    } else if (returnUrl) {
      this.$router.push(returnUrl);
    }
  }
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function postMessageToParent(payload) {
  window.parent.postMessage(payload, window.location.origin);
}

/**
 * @returns Promise that resolves with [lng, lat]
 */
export function getCurrentLocation() {
  const locationPromise = new Promise((resolve, reject) => {
    const success = (position) =>
      resolve([position.coords.longitude, position.coords.latitude]);
    const error = (err) => reject(err);
    navigator.geolocation.getCurrentPosition(success, error);
  });

  const importPromise = Promise.all([
      import('us-atlas/nation-10m.json'),
      import('@turf/boolean-point-in-polygon'),
      import('topojson-client')
    ]).catch(() => null)

  return Promise.all([locationPromise, importPromise])
    .then(([position, imported]) => {
      try {
        if (imported[0] && imported[1] && imported[2]) {
          if (!booleanPointInPolygon(position, imported[2].feature(imported[0], imported[0].objects.nation).features[0])) {
            return [-122.4194, 37.7749];
          }
        }
      } catch (err) {
        console.log(err);
      }
      return position;
    });
}

export function isMobleOrTablet() {
  let check = false;
  (function(a) {
    if (regex.mobileUA.test(a) || regex.tabletUA.test(a.substr(0, 4)))
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

// export function compress(data, encoding = "StorageBinaryString") {
//   return new Promise((resolve, reject) => {
//     if ([String, Uint8Array, Buffer].every(t => data.constructor != t)) {
//       data = JSON.stringify(data);
//     }
//     LZUTF8.compressAsync(
//       data,
//       { outputEncoding: encoding },
//       (result, error) => {
//         if (error) {
//           reject(error);
//         } else {
//           // each character is 16-bits i.e. 2 bytes
//           // const sizeInMB = result.length * 2 / 1024 / 1024;
//           resolve(result);
//         }
//       }
//     );
//   });
// }

// export function decompress(data, encoding = "StorageBinaryString") {
//   return new Promise((resolve, reject) => {
//     LZUTF8.decompressAsync(
//       data,
//       { inputEncoding: encoding },
//       (result, error) => {
//         if (error) {
//           reject(error);
//         } else {
//           resolve(result);
//         }
//       }
//     );
//   });
// }

export function isIE() {
  const ua = window.navigator.userAgent;
  return ua.indexOf("MSIE ") > 0 || !!ua.match(regex.tridentUA);
}

export function getPerpendicularPosition(x, y, width, height) {
  return {
    x: x - Math.round((width - height) / 2),
    y: y - Math.round((height - width) / 2)
  };
}

export function getSupportedAlignments(isLandscape = false) {
  return isLandscape ? ['tl', 'tr', 'ml', 'mr', 'bl', 'br'] : ['tl', 'tc', 'tr', 'bl', 'bc', 'br'];
}

export function getEDDMIndiciaParams(width, height, ppi, bleed_in, trim_in, fold_in, fold, align) {
  if (!fold_in) {
    fold_in = trim_in;
  }
  if (fold) {
    fold = fold.split('-');
  } else {
    fold = [];
  }
  let verticalFoldLine = height <= width;
  if (fold[1] == 'a') {
    // alternate variant
    verticalFoldLine = !verticalFoldLine;
  }
  fold = fold[0];
  // set this to false if you want address and indicia blocks to be placed inside trim offset and not close to the edge
  const isEdgeToEdge = false;
  const safe = (bleed_in + (isEdgeToEdge ? 0 : trim_in)) * ppi;
  const gap_fold = fold_in * ppi;
  const gap_bleed = bleed_in * ppi;
  const availableWidth = width - 2 * gap_bleed;
  const availableHeight = height - 2 * gap_bleed;
  // indicia
  const indicia_width = Math.round(ppi * dimensions.eddm.indicia.width_in);
  const indicia_height = Math.round(ppi * dimensions.eddm.indicia.height_in);
  // address block
  const address_width = Math.round(ppi * dimensions.eddm.address.width_in);
  const address_height = Math.round(ppi * dimensions.eddm.address.height_in);
  let leftEdge, rightEdge, topEdge, bottomEdge, page, faceWidth, faceHeight, rotate = 0;
  switch (fold) {
    case 'half':
      page = 0;
      if (verticalFoldLine) {
        faceWidth = availableWidth / 2;
        faceHeight = availableHeight;
        rightEdge = width / 2 - gap_fold;
        leftEdge = safe;
        topEdge = safe;
      } else {
        faceWidth = availableWidth;
        faceHeight = availableHeight / 2;
        rightEdge = width - safe;
        leftEdge = safe;
        topEdge = safe;
      }
      break;
    case 'c':
    case 'tri':
      page = 0;
      if (verticalFoldLine) {
        faceWidth = availableWidth / 3;
        faceHeight = availableHeight;
        rightEdge = faceWidth * 2 + gap_bleed - gap_fold;
        leftEdge = faceWidth + gap_bleed + gap_fold;
        topEdge = safe;
      } else {
        faceWidth = availableWidth;
        faceHeight = availableHeight / 3;
        topEdge = faceHeight + gap_bleed + gap_fold;
        leftEdge = safe;
        rightEdge = width - safe;
      }
      break;
    case 'z':
      page = 1;
      if (verticalFoldLine) {
        faceWidth = availableWidth / 3;
        faceHeight = availableHeight;
        rightEdge = faceWidth + gap_bleed - gap_fold;
        leftEdge = safe;
        topEdge = safe;
      } else {
        faceWidth = availableWidth;
        faceHeight = availableHeight / 3;
        topEdge = safe;
        rightEdge = width - safe;
        leftEdge = safe;
      }
      break;
    default:
      page = 1;
      faceWidth = availableWidth;
      faceHeight = availableHeight;
      rightEdge = width - safe;
      topEdge = safe;
      leftEdge = safe;
      break;
  }
  // if (window.cbad && faceWidth < faceHeight) {
  //   rotate = -90;
  //   rightEdge = null;
  //   leftEdge = Math.round(leftEdge);
  // } else {
  //   rotate = 0;
  //   leftEdge = null;
  //   rightEdge = Math.round(rightEdge);
  // }
  // topEdge = Math.round(topEdge);
  leftEdge = Math.round(leftEdge);
  rightEdge = Math.round(rightEdge);
  bottomEdge = Math.round(topEdge + faceHeight - safe);
  topEdge = Math.round(topEdge);
  const total_width = indicia_width + address_width;
  const total_height = Math.max(indicia_height, address_height);
  const isLandscape = faceWidth > faceHeight;
  const supportedAlignments = getSupportedAlignments(isLandscape);
  if (!align || supportedAlignments.indexOf(align) == -1) {
    align = 'tr';
  }
  let x1, y1, x2, y2;
  switch (align) {
    case 'tl':
      x1 = leftEdge;
      y1 = y2 = topEdge;
      x2 = x1 + address_width;
      break;
    case 'tc':
      y1 = y2 = topEdge;
      x1 = Math.round((leftEdge + rightEdge - total_width) / 2);
      x2 = x1 + address_width;
      break;
    case 'ml':
      rotate = -90;
      x1 = x2 = leftEdge;
      y1 = Math.round((topEdge + bottomEdge - total_width) / 2);
      y2 = y1 + address_width;
      break;
    case 'mr':
      rotate = 90;
      x1 = x2 = rightEdge - total_height;
      y1 = Math.round((topEdge + bottomEdge - total_width) / 2);
      y2 = y1 + address_width;
      break;
    case 'bl':
      if (!isLandscape) {
        rotate = 180;
      }
      x1 = leftEdge;
      x2 = x1 + address_width;
      y1 = y2 = bottomEdge - total_height;
      break;
    case 'bc':
      if (!isLandscape) {
        rotate = 180;
      }
      y1 = y2 = bottomEdge - total_height;
      x1 = Math.round((leftEdge + rightEdge - total_width) / 2);
      x2 = x1 + address_width;
      break;
    case 'br':
      if (!isLandscape) {
        rotate = 180;
      }
      x2 = rightEdge - indicia_width;
      x1 = x2 - address_width;
      y1 = y2 = bottomEdge - total_height;
      break;
    default: // tr is default
      x2 = rightEdge - indicia_width;
      x1 = x2 - address_width;
      y1 = y2 = topEdge;
      break;
  }
  
  // text = "*********ECRWSSEDDM****\nLocal Residential Customer";
  const address = {
    width: address_width,
    height: address_height,
    x: x1,
    y: y1,
    text: "RESERVED FOR ADDRESS\nDO NOT OVERWRITE"
  };
  // if (rotate == 0) {
  //   address.x = rightEdge - address_width - indicia_width;
  //   address.y = topEdge;
  // } else {
  //   address.x = leftEdge;
  //   address.y = topEdge + indicia_width; //indicia width = indicia height
  // }

  // let text = "PRESORT STD\nECRWSS\nU.S. POSTAGE\nPAID\nSACRAMENTO, CA\nPERMIT NO. 1827";
  const indicia = {
    width: indicia_width,
    height: indicia_height,
    text: "RESERVED\nFOR\nINDICIA",
    x: x2,
    y: y2,
    rotate
  };
  // if (rotate == 0) {
  //   indicia.x = rightEdge - indicia_width;
  // } else {
  //   indicia.x = leftEdge;
  // }

  return { indicia, address, page, rotate, align, supportedAlignments };
}

export function getDMAddressParams(size, width, height, ppi, bleed_in, trim_in, fold_in, align, windows) {
  if (!fold_in) {
    fold_in = trim_in;
  }
  const dimension = getDimension(size, windows)
  if (!align) {
    align = dimension.placement.anchor;
  }
  let fold = dimension.fold;
  let forceAlternate = false;
  if (fold) {
    if (typeof(fold) == 'string') {
      fold = fold.split('-');
      forceAlternate = fold[1] == 'a';
      fold = fold[0];
    } else {
      fold = fold.sizes_in.map(f => f * ppi);
    }
  } else {
    fold = null;
  }
  let verticalFoldLine = height <= width;
  if (forceAlternate) {
    // alternate variant
    verticalFoldLine = !verticalFoldLine;
  }
  // // set this to false if you want address and indicia blocks to be placed inside trim offset and not close to the edge
  // const isEdgeToEdge = true;
  // const safe = (bleed_in + (isEdgeToEdge ? 0 : trim_in)) * ppi;
  const safe = 0; // all offsets in dm specs are from the edge (be it bleed or trim)
  const gap_fold = fold_in * ppi;
  const gap_bleed = bleed_in * ppi;
  const availableWidth = width - 2 * gap_bleed;
  const availableHeight = height - 2 * gap_bleed;
  // address block
  const address_width = Math.round(ppi * dimension.width_in);
  const address_height = Math.round(ppi * dimension.height_in);
  let leftEdge, rightEdge, topEdge, bottomEdge, page, faceWidth, faceHeight, rotate = 0;
  if (Array.isArray(fold)) {
    // individual fold size specified (only implemented for bi-folds for now)
    const faceCount = fold.length + 1;
    page = 0;
    if (verticalFoldLine) {
      faceWidth = fold[0];
      faceHeight = availableHeight;
      rightEdge = width / faceCount - gap_fold;
      leftEdge = safe;
      topEdge = safe;
    } else {
      faceWidth = availableWidth;
      faceHeight = fold[0];
      rightEdge = width - safe;
      leftEdge = safe;
      topEdge = safe;
    }
  } else {
    switch (fold) {
      case 'half':
        page = 0;
        if (verticalFoldLine) {
          faceWidth = availableWidth / 2;
          faceHeight = availableHeight;
          rightEdge = width / 2;
          leftEdge = safe;
          topEdge = safe;
        } else {
          faceWidth = availableWidth;
          faceHeight = availableHeight / 2;
          rightEdge = width - safe;
          leftEdge = safe;
          topEdge = safe;
        }
        break;
      case 'c':
      case 'tri':
        page = 0;
        if (verticalFoldLine) {
          faceWidth = availableWidth / 3;
          faceHeight = availableHeight;
          rightEdge = faceWidth * 2 + gap_bleed;
          leftEdge = faceWidth + gap_bleed;
          topEdge = safe;
        } else {
          faceWidth = availableWidth;
          faceHeight = availableHeight / 3;
          topEdge = faceHeight + gap_bleed;
          leftEdge = safe;
          rightEdge = width - safe;
        }
        break;
      case 'z':
        page = 1;
        if (verticalFoldLine) {
          faceWidth = availableWidth / 3;
          faceHeight = availableHeight;
          rightEdge = faceWidth + gap_bleed;
          leftEdge = safe;
          topEdge = safe;
        } else {
          faceWidth = availableWidth;
          faceHeight = availableHeight / 3;
          topEdge = safe;
          rightEdge = width - safe;
          leftEdge = safe;
        }
        break;
      default:
        page = 1;
        faceWidth = availableWidth;
        faceHeight = availableHeight;
        rightEdge = width - safe;
        topEdge = safe;
        leftEdge = safe;
        break;
    }
  }
  // if (window.cbad && faceWidth < faceHeight) {
  //   rotate = -90;
  //   rightEdge = null;
  //   leftEdge = Math.round(leftEdge);
  // } else {
  //   rotate = 0;
  //   leftEdge = null;
  //   rightEdge = Math.round(rightEdge);
  // }
  // topEdge = Math.round(topEdge);
  bottomEdge = (topEdge || gap_bleed) + faceHeight - safe;
  if (bottomEdge + gap_bleed == height) {
    bottomEdge = height;
  }
  leftEdge = Math.round(leftEdge);
  rightEdge = Math.round(rightEdge);
  bottomEdge = Math.round(bottomEdge);
  topEdge = Math.round(topEdge);
  const total_width = address_width;
  const total_height = address_height;
  const offset_x = Math.round(dimension.offset_x_in * ppi);
  const offset_y = Math.round(dimension.offset_y_in * ppi);
  const isLandscape = faceWidth > faceHeight;
  // const supportedAlignments = getSupportedAlignments(isLandscape);
  // if (!align || supportedAlignments.indexOf(align) == -1) {
  //   align = 'tr';
  // }
  let x, y;
  switch (align) {
    case 'tl':
      x = leftEdge + offset_x;
      y = topEdge + offset_y;
      break;
    case 'tc':
      y = topEdge + offset_y;
      x = Math.round((leftEdge + rightEdge - total_width) / 2);
      break;
    case 'tr':
      x = rightEdge - address_width - offset_x;
      y = topEdge + offset_y;
      break;
    case 'ml':
      rotate = -90;
      x = leftEdge + offset_x;
      y = Math.round((topEdge + bottomEdge - total_width) / 2);
      break;
    case 'mr':
      rotate = 90;
      x = rightEdge - total_height - offset_x;
      y = Math.round((topEdge + bottomEdge - total_width) / 2);
      break;
    case 'bl':
      if (!isLandscape) {
        rotate = 180;
      }
      x = leftEdge + offset_x;
      y = bottomEdge - total_height - offset_y;
      break;
    case 'bc':
      if (!isLandscape) {
        rotate = 180;
      }
      y = bottomEdge - total_height - offset_y;
      x = Math.round((leftEdge + rightEdge - total_width) / 2);
      break;
    default: // br is default
      if (!isLandscape) {
        rotate = 180;
      }
      x = rightEdge - address_width - offset_x;
      y = bottomEdge - total_height - offset_y;
      break;
  }
  
  // text = "*********ECRWSSEDDM****\nLocal Residential Customer";
  const address = {
    width: address_width,
    height: address_height,
    x,
    y,
    text: "RESERVED FOR ADDRESS\nDO NOT OVERWRITE"
  };

  const fixedPage = +(dimension.placement && dimension.placement.page) - 1;

  return { address, rotate, align, page: isNaN(fixedPage) ? page : fixedPage, orientation: dimension.orientation };
}

export function getDimension(size, windows) {
  if (!windows) {
    windows = 2;
  }
  let dimension = dimensions.dm[size];
  if (size == 1) {
    // letter, need window count
    if (windows == 2) {
      dimension = dimension.letter_double;
    } else {
      dimension = dimension.letter_single;
    }
  }
  return dimension;
}

export function getDesignStudioParams(config, extra = {}) {
  const query = {};
  if (config != null && typeof(config) == 'object') {
    query.size = config.size;
    if (query.size == 1) {
      // letter, set envelope window count
      query.windows = config.windows || 1;
    }
    if (config.order) {
      query.order = config.order;
    } else if (config.cart) {
      query.cart = config.cart || localStorage.getItem('cartId');
    }
    if (config.designId) {
      // editing existing design
      query.from = config.designId;
    }
    if (Array.isArray(config.files) && config.files.length == 2) {
      // uploaded documents
      query.front = config.files[0];
      query.back = config.files[1];
    }

    if (config.service == 'dm') {
      // direct mail
      query.service = 5;
      if (config.color == 'grayscale') {
        query.grayscale = 1;
      }
      if (config.extraSheets > 0) {
        query.extraSheets = config.extraSheets;
      }
    } else if (config.service == 'eddm') {
      // every door direct mail
      query.service = 1;
    } else if (config.service == 'print') {
      // print only
      query.service = 2;
    } else {
      query.standalone = 1;
    }
  }
  return { ...query, ...extra };
}

export function toArray(obj) {
  if (Array.isArray(obj)) {
    return obj;
  } else if (obj == null || obj == undefined) {
    return [];
  } else if (typeof(obj) == "object") {
    return Object.keys(obj).sort((a, b) => a - b).map(k => obj[k]);
  } else {
    return [obj];
  }
}

export function getMatchPriority(term, text) {
  text = (text || '').toLowerCase();
  const index = text.indexOf(term);
  let priority;
  let count;
  let match_percent;
  if (index == -1) {
    count = 0;
    const words = term.split(/\s/);
    if (words.length > 1) {
      let match_count = 0;
      words.forEach(w => {
        if (text.indexOf(w) != -1) {
          match_count += 1;
        }
      });
      match_percent = match_count / words.length;
    } else {
      match_percent = 0;
    }
    if (count > 0 || match_percent > 0) {
      priority = 1000 + count * 100 + match_percent * 1000;
    } else {
      priority = -1;
    }
  } else {
    count = (text.match(term) || []).length;
    match_percent = 1;
    priority = index + count * 100 + match_percent * 1000;
  }
  return priority;
}

export function getFileName(url, on_empty = '') {
  url = (url || '').toString();
  if (url) {
    const index = url.lastIndexOf('/') + 1;
    return url.substring(index, url.length) || on_empty;
  } else {
    return on_empty;
  }
}
export function padString(str, length, padding = "0", padEnd = false) {
  if (!str) {
    str = '';
  } else if (typeof(str) != "string") {
    str = str.toString();
  }
  const required = length - str.length;
  for (let j = 0; j < required; ++j) {
    if (padEnd) {
      str += padding;
    } else {
      str = padding + str;
    }
  }
  return str;
}

/**
 * Choose one variant from an array of variants and return the same variant for consecutive requests.
 * @param {string} name prefix / name of the test
 * @param {{weight: number}[]} variants fractional weights of each variant. should add upto 1
 * @param {number} timeout number of days which the selected variant is valid for
 * @returns 
 */
export function chooseVariant(name = '', variants = [{ weight: 0.5 }, { weight: 0.5 }], chooser = 'random', timeout = 30) {
  const cookieName = 'test_' + name;
  const value = Cookies.get(cookieName);
  const valueNumber = Number(value);
  if (value && value.length > 0 && valueNumber >= 0 && valueNumber < variants.length) {
    return valueNumber;
  } else {
    // normalize weights
    const total = variants.reduce((p, c) => p + c.weight, 0);
    variants.forEach(x => x.weight = x.weight / total);

    let choice;
    switch (chooser) {
      case 'random':
        {
          const random = Math.random();
          let sum = 0, i = 0;
          for (; i < variants.length; ++i) {
            sum += variants[i].weight;
            if (random <= sum) {
              break;
            }
          }
          choice = i;
        }
        break;
    }

    if (typeof(choice) == 'number' && choice < variants.length) {
      Cookies.set(cookieName, choice.toString(), { expires: timeout });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: cookieName + choice,
        variant: {
          choice,
          timeout,
          max: variants.length
        }
      });
      return choice;
    } else {
      throw "variant-chooser: Invalid variant selected";
    }
  }
}

export function collide(el1, el2) {
  var rect1 = el1.getBoundingClientRect();
  var rect2 = el2.getBoundingClientRect();

  return !(
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right
  );
}

export async function consultFunction() {
  if (!this.$root.cartService.cart) {
    await this.$root.cartService.getCart();
  }
  const cart = this.$root.cartService.cart && this.$root.cartService.cart.cartContents;
  if (!Array.isArray(cart) || cart.every(item => !item.servicetype || item.servicetype.id != 4)) {
    await this.$axios.post("addproductDetails", {
      cartID: this.$route.query.cart || localStorage.getItem("cartId"),
      servicetype: 4,
      designchoice: "consult",
      quantity: 1,
    });
    this.$root.facebookService.track("AddToCart", {
      content_type: "product_group",
      contents: [{
        id: 4,
        quantity: 1
      }],
      value: 30,
      currency: "USD"
    });
    return true;
  }
  return false;
}

export function sanitize(html) {
  const e = document.createElement('textarea');
  e.innerHTML = html;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function findLastIndex(array, iterable) {
  for (let i = array.length - 1; i >= 0; --i) {
    if (iterable(array[i])) return i;
  }
}

export function getRandomAlphaNumeric(length = 6) {
  let text = '';
  while (length-- > 0) {
    const num = Math.round(Math.random() * 35);
    if (num > 9) {
      text += String.fromCharCode(num - 9 + 65);
    } else {
      text += String.fromCharCode(num + 48);
    }
  }
  return text;
}

export function getParamsFromDelta(delta) {
  if (delta && delta.ops && delta.ops.length) {
    const params = {
      text: '',
      // bold: false,
      // italic: false,
      // underline: false,
      // strikethrough: false,
      // color: null,
      // script: null,
    };
    for (const op of delta.ops) {
      if (op.insert) {
        if (op.attributes) {
          const start = params.text.length;
          const end = start + op.insert.length;
          for (const attr in op.attributes) {
            if (['italic', 'underline', 'strikethrough'].indexOf(attr) != -1) {
              // format change
              if (!params[attr]) {
                params[attr] = {};
              }
              params[attr][start] = end;
            } else if (['size', 'color', 'script', 'bold'].indexOf(attr) != -1) {
              // style change
              const key = attr == 'bold' ? 'weight' : attr;
              const value = attr == 'bold' ? 700 : op.attributes[attr];
              if (!params[key]) {
                params[key] = {};
              }
              params[key][start] = { end, value };
            }
          }
        }
        params.text += op.insert;
      }
    }
    return params;
  }
  return null;
}

/* https://stackoverflow.com/questions/28360978/css-how-to-get-browser-scrollbar-width-for-hover-overflow-auto-nice-margi */
export function getScrollbarWidth() {
  const scrollDiv = document.createElement("div");
  scrollDiv.style.width = '100px';
  scrollDiv.style.height = '100px';
  scrollDiv.style.overflow = 'scroll';
  scrollDiv.style.position = 'absolute';
  scrollDiv.style.top = '-9999px';

  document.body.appendChild(scrollDiv);

  // Get the scrollbar width
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Delete the div
  document.body.removeChild(scrollDiv);
  
  return scrollbarWidth;
}

/* eslint-disable */
export const regex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/,
  zip5: /^\d{5}$/,
  looseZip5: /^\d{4,5}$/,
  mobileUA: /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
  tabletUA: /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
  tridentUA: /Trident.*rv\:11\./,
  commaSeparated: /\s*[,]\s*/,
  // url: /^(https?([:][/]{2}))?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
  url: new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'), // fragment locator
  crid: /^[1-9]\d{0,14}$/,
  mid: /^[1-9](\d{5}|\d{8})$/,
  serial: /^(\d{6}|\d{9})$/,
  integer: /^\d+$/,
};

export const regexColumns = {
  firstName: /^(person[\s_-]?)?first[\s_-]?name$/i,
  lastName: /^(person[\s_-]?)?last[\s_-]?name$/i,
  company: /^company([\s_-]?name)?$/i,
  address: /^((physical|street)[\s_-]?)?address([\s_-]?line1)?|house[\s_-]?number[\s_-]?and[\s_-]?street$/i,
  city: /^(physical[\s_-]?)?city$/i,
  state: /^(physical[\s_-]?)?(state|st)$/i,
  postal: /^(physical[\s_-]?)?(zip([\s_-]?code)?|(postal|post)([\s_-]?code)?)$/i,
  date: /^((conversion|order|churn|invoice)[\s_-]?)?date([\s_-]?time)?$/i,
  value: /^((conversion|order|churn|invoice)[\s_-]?)?value$/i,
  age: /^age$/i,
  income: /^income$/i,
  gender: /^gender$/i,
  barcode: /^(usps_?|imb?_?)?barcode$/i,
  barcodeMID: /^(usps_?|imb?_?)?(barcode_?)?(mid|mailer_?id)$/i,
};
/* eslint-enable */

/* default address block offset is the safe area */
export const dimensions = {
  eddm: {
    indicia: {
      size_in: 0.8,
      width_in: 1,
      height_in: 0.8,
    },
    address: {
      width_in: 1.6,
      height_in: 0.8
    },
  },
  dm: {
    1: { // letter 8.5 x 11
      letter_single: {
        width_in: 3.15,
        height_in: 1.3,
        offset_x_in: 0.8,
        offset_y_in: 1.54,
        placement: {
          page: 1,
          anchor: 'tl',
        },
        bleed_in: 0,
        trim_in: 0.0625,
        orientation: 'portrait',
        extra_overlays: [
          {
            label: '2D Barcode',
            width_in: 0.5,
            height_in: 0.5,
            offset_x_in: 0.087,
            offset_y_in: 10.413,
            placement: {
            //   anchor: 'bl',
              page: 1,
            },
          },
          // {
          //   label: 'Serial Number',
          //   width_in: 0.1,
          //   height_in: 3,
          //   offset_x_in: 0.1,
          //   // offset_x_in: 0.087,
          //   // offset_y_in: 2.25,
          //   offset_y_in: 6,
          //   placement: {
          //     sheet: 1,
          //     page: 1,
          //     // anchor: 'bl',
          //   },
          // },
        ],
      },
      letter_double: {
        width_in: 3.15,
        height_in: 2,
        offset_x_in: 0.6,
        offset_y_in: 0.84,
        placement: {
          page: 1,
          anchor: 'tl',
        },
        bleed_in: 0,
        trim_in: 0.0625,
        orientation: 'portrait',
        extra_overlays: [
          {
            label: '2D Barcode',
            width_in: 0.5,
            height_in: 0.5,
            offset_x_in: 0.087,
            offset_y_in: 10.413,
            placement: {
            //   anchor: 'bl',
              page: 1,
            },
          },
          // {
          //   label: 'Serial Number',
          //   width_in: 0.1,
          //   height_in: 3,
          //   offset_x_in: 0.1,
          //   // offset_x_in: 0.087,
          //   // offset_y_in: 2.25,
          //   offset_y_in: 6,
          //   placement: {
          //     sheet: 1,
          //     page: 1,
          //     // anchor: 'bl',
          //   },
          // },
        ],
      },
    },
    2: { // self mailer 6 x 18
      width_in: 4,
      height_in: 2.375,
      offset_x_in: 0.15,
      offset_y_in: 0.25,
      placement: {
        page: 1,
        anchor: 'br',
      },
      bleed_in: 0.125,
      trim_in: 0,
      fold: 'half',
      orientation: 'landscape',
    },
    3: { // self mailer 11 x 9
      width_in: 4,
      height_in: 2.375,
      offset_x_in: 4.875,
      offset_y_in: 3.475,
      placement: {
        page: 1,
        anchor: 'tl',
      },
      bleed_in: 0.125,
      trim_in: 0,
      fold: {
        type: 'half',
        sizes_in: [6],
      },
      orientation: 'portrait',
    },
    4: { // self mailer 12 x 9
      width_in: 4,
      height_in: 2.375,
      offset_x_in: 0.25,
      offset_y_in: 0.15,
      placement: {
        page: 1,
        anchor: 'br',
      },
      bleed_in: 0.125,
      trim_in: 0,
      fold: 'half',
      orientation: 'portrait',
    },
    5: { // postcard 4 x 6
      width_in: 3.2835,
      height_in: 2.375,
      offset_x_in: 0.275,
      offset_y_in: 0.25,
      placement: {
        page: 2,
        anchor: 'br',
      },
      bleed_in: 0.125,
      trim_in: 0,
      orientation: 'landscape',
    },
    6: { // postcard 6 x 11
      width_in: 4,
      height_in: 2.375,
      offset_x_in: 0.275,
      offset_y_in: 0.25,
      placement: {
        page: 2,
        anchor: 'br',
      },
      bleed_in: 0.125,
      trim_in: 0,
      orientation: 'landscape',
    },
    7: { // postcard 6 x 9
      width_in: 4,
      height_in: 2.375,
      offset_x_in: 0.275,
      offset_y_in: 0.25,
      placement: {
        page: 2,
        anchor: 'br',
      },
      bleed_in: 0.125,
      trim_in: 0,
      orientation: 'landscape',
    },
  },
  default_ppi: 350,
  default_bleed_in: 0.125,
};


export const us_administrative_areas_list = [
  { long: 'Alabama',	short: 'AL' },
  { long: 'Alaska',	short: 'AK' },
  { long: 'Arizona',	short: 'AZ' },
  { long: 'Arkansas',	short: 'AR' },
  { long: 'California',	short: 'CA' },
  { long: 'Colorado',	short: 'CO' },
  { long: 'Connecticut',	short: 'CT' },
  { long: 'Delaware',	short: 'DE' },
  { long: 'District of Columbia',	short: 'DC' },
  { long: 'Florida',	short: 'FL' },
  { long: 'Georgia',	short: 'GA' },
  { long: 'Hawaii',	short: 'HI' },
  { long: 'Idaho',	short: 'ID' },
  { long: 'Illinois',	short: 'IL' },
  { long: 'Indiana',	short: 'IN' },
  { long: 'Iowa',	short: 'IA' },
  { long: 'Kansas',	short: 'KS' },
  { long: 'Kentucky',	short: 'KY' },
  { long: 'Louisiana',	short: 'LA' },
  { long: 'Maine',	short: 'ME' },
  { long: 'Maryland',	short: 'MD' },
  { long: 'Massachusetts',	short: 'MA' },
  { long: 'Michigan',	short: 'MI' },
  { long: 'Minnesota',	short: 'MN' },
  { long: 'Mississippi',	short: 'MS' },
  { long: 'Missouri',	short: 'MO' },
  { long: 'Montana',	short: 'MT' },
  { long: 'Nebraska',	short: 'NE' },
  { long: 'Nevada',	short: 'NV' },
  { long: 'New Hampshire',	short: 'NH' },
  { long: 'New Jersey',	short: 'NJ' },
  { long: 'New Mexico',	short: 'NM' },
  { long: 'New York',	short: 'NY' },
  { long: 'North Carolina',	short: 'NC' },
  { long: 'North Dakota',	short: 'ND' },
  { long: 'Ohio',	short: 'OH' },
  { long: 'Oklahoma',	short: 'OK' },
  { long: 'Oregon',	short: 'OR' },
  { long: 'Pennsylvania',	short: 'PA' },
  { long: 'Rhode Island',	short: 'RI' },
  { long: 'South Carolina',	short: 'SC' },
  { long: 'South Dakota',	short: 'SD' },
  { long: 'Tennessee',	short: 'TN' },
  { long: 'Texas',	short: 'TX' },
  { long: 'Utah',	short: 'UT' },
  { long: 'Vermont',	short: 'VT' },
  { long: 'Virginia',	short: 'VA' },
  { long: 'Washington',	short: 'WA' },
  { long: 'West Virginia',	short: 'WV' },
  { long: 'Wisconsin',	short: 'WI' },
  { long: 'Wyoming',	short: 'WY' },
  { long: 'American Samoa',	short: 'AS' },
  { long: 'Guam',	short: 'GU' },
  { long: 'Northern Mariana Islands',	short: 'MP' },
  { long: 'Puerto Rico',	short: 'PR' },
  { long: 'U.S. Virgin Islands',	short: 'VI' },
  { long: 'U.S. Minor Outlying Islands',	short: 'UM' },
];

export const design_service_statuses = {
  recieved: 'Received',
  received: 'Received',
  paid: 'Payment Done',
  upload: 'Creative Uploaded',
  complete: 'Fulfilled',
  completed: 'Fulfilled',
  approved: 'Approved',
  unknown: 'Unknown State'
};

export const rich_text_types = {
  faq: 'json-ld-faq',
  search: 'json-ld-search',
};

export const themeColors = {
  text: "#6b7280",
  blue: "#0D19A3",
  darkBlue: "#080F5B",
  lightBlue: "#67E8F9",
  green: "#14db96",
  lightGreen: "#78CF46",
  red: "#FB7185",
  purple: "#AFADF7",
  orange: "#ffcc00",
  yellow: "#ffdd00",
};

export const chartColors = [
  themeColors.purple,
  themeColors.lightBlue,
  themeColors.red,
  themeColors.orange,
  themeColors.green,
  themeColors.text,
  themeColors.blue,
  themeColors.lightGreen,
  themeColors.yellow,
];

// defines the sequence of parameters for each row in logic array
// will evaluate to first 0
// field - name of the column in csv (fields)
// operator - one of '=', '<', '>', '<=', '>=', '!=', 'has'
// value - value of the field to compare against
// result - output of the logic if the condition succeeds
// children - array of nested logic blocks if any
export const FORMAT_SEQUENCE = ['id', 'field', 'operator', 'value', 'result', 'children'];
export const OPERATORS = [
  { text: 'Equals To', value: '=' },
  { text: 'Not Equals To', value: '!=' },
  { text: 'Less Than', value: '<' },
  { text: 'Greater Than', value: '>' },
  { text: 'Less Than or Equals To', value: '<=' },
  { text: 'Greater Than or Equals To', value: '>=' },
  { text: 'Has', value: 'has' },
];

export const MINIMUM = {
  direct_mail: process.env.VUE_APP_ENV_NAME == 'production' ? 500 : 9,
  build_audience: process.env.VUE_APP_ENV_NAME == 'production' ? 500 : 10,
  upload_audience: process.env.VUE_APP_ENV_NAME == 'production' ? 500 : 9,
  model: process.env.VUE_APP_ENV_NAME == 'production' ? 1000 : 9,
  model_additional: process.env.VUE_APP_ENV_NAME == 'production' ? 500 : 10,
};

export const ALL_PREDICTION_MODEL_TYPES = [
  {
    text: 'Lookalike audience',
    value: 'lookalike',
    visible: false,
  },
  {
    text: 'Lead scoring',
    value: 'lead_scoring',
    visible: true,
  },
  {
    text: 'Churn reduction',
    value: 'churn_reduction',
    visible: true,
  },
  {
    text: 'Persona assignment',
    value: 'persona_assignment',
    visible: true,
  },
  {
    text: 'Other',
    value: 'other',
    visible: true,
    other: true,
  },
];

export const INFORMED_DELIVERY_CONSTRAINTS = {
  brandDisplayNameMaxLength: 40,
  rideAlongImageMaxSize: 200 / 1024, // 200 KB
  rideAlongImageMaxWidth: 300,
  rideAlongImageMaxHeight: 200,
  rideAlongImageFormats: '.jpg,.jpeg',
  rideAlongTargetURLMaxLength: 255,
  representativeImageMaxSize: 200 / 1024, // 200 KB
  representativeImageMaxWidth: 780,
  representativeImageMaxHeight: 500,
  representativeImageFormats: '.jpg,.jpeg',
};
import Vue from 'vue';
export const EventBus = new Vue();

export const EventTypes = {
  MORE_ACTIONS: 'MORE_ACTIONS',
  SHOW_HOVER_MENU: 'SHOW_HOVER_MENU',
  HIDE_HOVER_MENU: 'HIDE_HOVER_MENU',
  DESIGN_NEXT: 'DESIGN_NEXT',
  EMIT: 'EMIT',
  DESIGN_HELP: 'DESIGN_HELP',
  REQUEST_QUOTE: 'REQUEST_QUOTE',
  LOGIN_POPUP: 'LOGIN_POPUP',
  AUTH_CHANGED: 'AUTH_CHANGED',
  GLOBAL_LOADER: 'GLOBAL_LOADER',
  GLOBAL_MESSAGE: 'GLOBAL_MESSAGE',
  SHOW_EMAIL_POPUP: 'SHOW_EMAIL_POPUP',
  LOGOUT_IFRAME: 'LOGOUT_IFRAME',

  EDITOR: {
    // events that minipaint will raise exclusively
    MINIPAINT: {
      LAYER_CHANGED: 'EDITOR.MINIPAINT.LAYER_CHANGED',
      ADD_SHAPE: 'EDITOR.MINIPAINT.ADD_SHAPE',
      ADD_TEXT: 'EDITOR.MINIPAINT.ADD_TEXT',
      ADD_TABLE: 'EDITOR.MINIPAINT.ADD_TABLE',
      EDIT_TABLE: 'EDITOR.MINIPAINT.EDIT_TABLE',
      ADD_QR_CODE: 'EDITOR.MINIPAINT.ADD_QR_CODE',
      EDIT_QR_CODE: 'EDITOR.MINIPAINT.EDIT_QR_CODE',
      ENABLE_TEXT_EDIT: 'EDITOR.MINIPAINT.ENABLE_TEXT_EDIT',
      DISABLE_TEXT_EDIT: 'EDITOR.MINIPAINT.DISABLE_TEXT_EDIT',
      EDIT_VARIABLE_LOGIC: 'EDITOR.MINIPAINT.EDIT_VARIABLE_LOGIC',
      JSON_LOADED: 'EDITOR.MINIPAINT.JSON_LOADED',
      JSON_FAILED: 'EDITOR.MINIPAINT.JSON_FAILED',
      SAVED: 'EDITOR.MINIPAINT.SAVED',
      HISTORY_UPDATED: 'EDITOR.MINIPAINT.HISTORY_UPDATED',
      LAYER_INSERTED: 'EDITOR.MINIPAINT.LAYER_INSERTED',
      FONTS_UPDATED: 'EDITOR.MINIPAINT.FONTS_UPDATED'
    }
  },

  DROPPER: {
    COLOR_CHOSEN: 'DROPPER.COLOR_CHANGED'
  },

  CALENDLY: {
    EVENT_SCHEDULED: 'CALENDLY.EVENT_SCHEDULED'
  },

  CAMPAIGN: {
    AUDIENCE_CREATED: 'CAMPAIGN.AUDIENCE_CREATED',
    AUDIENCE_DONE: 'CAMPAIGN.AUDIENCE_DONE'
  }
};

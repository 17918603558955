import { axios } from "@/axios";

class VendastaService {
  authorize() {
    const search = location.search.substring(1);
    let data = {};
    if (search) {
      try {
        data = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

        return axios.post('ssologin', data).then(res => {
          location.href = res.data.location;
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
}

export const vendastaService = new VendastaService();
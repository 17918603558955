<template>
  <div
    id="app"
    class="app"
    :class="appClasses"
    :style="{marginTop: $refs.unsupported ? ($refs.unsupported.offsetHeight + 'px') : 0,}"
  >
    <div v-if="showUnsupportedBanner" :class="'unsupported-banner ' + (showUI && !hoverMenu || isMenuActive ? 'top-header' : 'top-0')" ref="unsupported">
      You are using an unsupported browser. Upgrade to one of following modern browsers to get the full experience:
      <ul class="browsers color-blue">
        <li class="mr-4"><a class="btn-link" href="https://www.google.com/chrome" target="_blank">Google Chrome</a></li>
        <li class="mr-4"><a class="btn-link" href="https://www.mozilla.org/en-US/firefox/new/" target="_blank">Mozilla Firefox</a></li>
        <li><a class="btn-link" href="https://www.microsoft.com/en-us/edge" target="_blank">Microsoft Edge</a></li>
      </ul>
    </div>
    <template v-if="showUI">
      <sidebar v-if="isAuthenticated" />
      <b-toaster name="b-toaster-below-header" class="top-header position-fixed w-100 text-center" />
      <router-view @richText="addRichText" class="main-view" />
    </template>
    <router-view @richText="addRichText" v-else />
    <login-popup v-if="showLoginPopup" v-model="showLoginPopup" />
    <design-help-popup v-if="showDesignHelp" v-model="showDesignHelp" />
    <request-quote-popup v-if="showQuotePopup" v-model="showQuotePopup" />
    <address-popup v-if="showAddressPopup" :title="addressTitle" v-model="showAddressPopup" :prefill="user" @saved="onAddressUpdated" />
    <b-modal :visible="!!globalMessage" centered hide-footer :title="globalMessage && globalMessage.title" @change="globalMessage = null">
      <p class="text-center font-size-1-25-em mb-4">{{globalMessage && globalMessage.message}}</p>
    </b-modal>
    <!-- <b-modal centered hide-footer :visible="!!emailPopupAction" @change="closeEmailPopup(false)" modal-class="email-popup">
      <template #modal-title><div class="font-weight-0-9-em">Receive Comprehensive Campaign Feedback from EDDM Experts in Your Inbox</div></template>
      <email-wall justifyContent="center" class="mb-4 mx-auto" input-class="font-weight-400" :once="true" @submit="closeEmailPopup(true)" :expand="true">PROCEED</email-wall>
    </b-modal> -->
    <loader v-if="globalLoader" class="position-fixed global-loader" :show-delay="true" />
    <transition name="slide-fade">
      <div v-if="showCookieBanner" class="cookie-banner">
        <p>We use cookies to ensure you get the best experience on our website.<br />Take a look at our <a class="color-primary-dark font-weight-500" :href="base + '/privacy-policy'" target="_blank">Privacy Policy</a> to learn more.</p>
        <button type="button" @click="hideCookieBanner" class="btn btn-primary btn-md ml-3 ml-md-5 flex-shrink-0">I Accept</button>
      </div>
    </transition>
    <div
      v-if="user"
      class="d-none"
      id="swell-customer-identification"
      data-authenticated="true"
      :data-email="user.email"
      :data-id="userID"
      :data-tags="`['${user.industry}']`"
    ></div>
  </div>
</template>
<script>
import { EventBus, EventTypes } from "@/event-bus.js";
// import `HeaderLogo` from "@/components/HeaderLogo.vue";
import Sidebar from "@/components/Sidebar.vue";
// import LoginPopup from "@/components/LoginPopup.vue";
// import DesignHelpPopup from "@/components/DesignHelpPopup.vue";
import Loader from "@/components/Loader.vue";
import { regex, inIframe, postMessageToParent, isIE } from "@/utils";
import { debounce } from "lodash";
import Cookies from "js-cookie";
import { BToaster } from "bootstrap-vue";
// import EmailWall from "@/components/EmailWall.vue";

const DesignHelpPopup = () => ({
  component: import("@/components/DesignHelpPopup.vue"),
  loading: Loader,
  error: null,
  timeout: 10000,
});

const LoginPopup = () => ({
  component: import("@/components/LoginPopup.vue"),
  loading: Loader,
  error: null,
  timeout: 10000,
});

const RequestQuotePopup = () => ({
  component: import("@/components/RequestQuotePopup.vue"),
  loading: Loader,
  error: null,
  timeout: 10000,
});

const AddressPopup = () => ({
  component: import("@/components/AddressPopup.vue"),
  loading: Loader,
  error: null,
  timeout: 10000,
});

export default {
  name: "App",
  components: {
    // HeaderLogo,
    LoginPopup,
    DesignHelpPopup,
    RequestQuotePopup,
    // EmailWall,
    BToaster,
    AddressPopup,
    Sidebar,
  },
  data() {
    return {
      base: process.env.VUE_APP_WORDPRESS_URL,
      showUI: false,
      showSidebar: false,
      showActionsMenuIcon: false,
      hoverMenu: false,
      isMenuActive: false,
      showLoginPopup: false,
      showQuotePopup: false,
      showStandardOptions: true,
      hasMoreActions: false,
      loading: {
        cart: false,
        name: false,
      },
      activeSubmenu: null,
      showSearch: true,
      searchVisible: false,
      searchTerm: "",
      showDesignHelp: false,
      showUserMenu: false,
      globalLoader: false,
      showUnsupportedBanner: isIE(),
      showCookieBanner: false,
      nestedSubmenu: null,
      globalMessage: null,
      headerRefs: ['headerMobile', 'headerMobile2', 'headerMenu'],
      headerStyles: [],
      headerScrolled: false,
      // autocompleteParams: {
      //   // types: ["address"],
      //   componentRestrictions: { country: ["us", "pr", "vi", "gu", "mp"] },
      // },
      selectedAddress: null,
      // emailPopupAction: null,
      showHeaderLinks: true,
      hideHeaderOnScroll: false,
      scrollTop: 0,
      headerHidden: false,
      // campaignName: '',
      // campaignPlaceholder: 'Enter title',
      // cartItem: null,
      // previousCartId: null,
      vendastaUrl: process.env.VUE_APP_VENDASTA_URL,
      showAddressPopup: false,
      addressTitle: '',
      showCampaignMenu: false,
    };
  },
  mounted() {
    this.setDocumentHeight();
    this.updateState();
    this.bindHeaderStyles();
    this.setEvents();
    // this.fetchCart();
    this.showCookieBanner = !inIframe() && !Cookies.get("acceptCookies");
  },
  beforeDestroy() {
    this.removeEvents();
    this.setAutocompleteVisibility(null, true);
  },
  methods: {
    setTimeout: debounce((method, delay) => {
      setTimeout(method, delay);
    }, 400),
    bindHeaderStyles() {
      setTimeout(() => {
        this.headerRefs.forEach(k => {
          if (this.$refs[k]) {
            this.headerStyles.push(window.getComputedStyle(this.$refs[k]));
          }
        });
      });
    },
    hideCookieBanner() {
      Cookies.set("acceptCookies", "true", { expires: 3650 });
      this.showCookieBanner = false;
    },
    updateState() {
      this.showSearch = true;
      this.showSidebar = false;
      this.showActionsMenuIcon = false;
      this.hoverMenu = false;
      this.showStandardOptions = true;
      this.hasMoreActions = false;
      this.showUserMenu = false;
      const path = this.$route.path;
      if (path === "/editor") {
        this.showUI = false;
      } else {
        this.showUI = true;
        // if (path === "/audience-pro" || path === "/design") {
        //   this.hoverMenu = true;
        //   this.showStandardOptions = false;
        //   this.hasMoreActions = true;
        // }
        if (path === "/design") {
          this.showActionsMenuIcon = true;
        }
        if (path.startsWith("/products")) {
          this.showSearch = false;
          this.searchVisible = false;
        }
      }
      this.showHeaderLinks = !this.$route.meta || typeof(this.$route.meta.hideHeaderLinks) == "string" ? this.$route.meta.hideHeaderLinks : !this.$route.meta.hideHeaderLinks;
      this.hideHeaderOnScroll = this.$route.meta && this.$route.meta.hideHeaderOnScroll;
      // this.updateCampaignName();
    },
    emitMoreActions() {
      EventBus.$emit(EventTypes.MORE_ACTIONS, true);
    },
    setEvents() {
      EventBus.$on(EventTypes.SHOW_HOVER_MENU, this.showHoverMenu);
      EventBus.$on(EventTypes.HIDE_HOVER_MENU, this.hideHoverMenu);
      EventBus.$on(EventTypes.DESIGN_HELP, this.setDesignHelp);
      EventBus.$on(EventTypes.REQUEST_QUOTE, this.setRequestQuote);
      EventBus.$on(EventTypes.LOGIN_POPUP, this.setLoginPopup);
      EventBus.$on(EventTypes.GLOBAL_LOADER, this.setGlobalLoader);
      EventBus.$on(EventTypes.GLOBAL_MESSAGE, this.setGlobalMessage);
      EventBus.$on(EventTypes.SHOW_EMAIL_POPUP, this.showEmailPopup);
      EventBus.$on(EventTypes.AUTH_CHANGED, this.onAuthChanged);
      document.addEventListener("click", this.onClick);
      window.addEventListener("resize", this.setDocumentHeight);
      window.addEventListener("orientationchange", this.setDocumentHeight);
      window.addEventListener("scroll", this.onScroll, { passive: true });
    },
    removeEvents() {
      EventBus.$off(EventTypes.SHOW_HOVER_MENU, this.showHoverMenu);
      EventBus.$off(EventTypes.HIDE_HOVER_MENU, this.hideHoverMenu);
      EventBus.$off(EventTypes.DESIGN_HELP, this.setDesignHelp);
      EventBus.$off(EventTypes.REQUEST_QUOTE, this.setRequestQuote);
      EventBus.$off(EventTypes.LOGIN_POPUP, this.setLoginPopup);
      EventBus.$off(EventTypes.GLOBAL_LOADER, this.setGlobalLoader);
      EventBus.$off(EventTypes.GLOBAL_MESSAGE, this.setGlobalMessage);
      EventBus.$off(EventTypes.SHOW_EMAIL_POPUP, this.showEmailPopup);
      EventBus.$off(EventTypes.AUTH_CHANGED, this.onAuthChanged);
      document.removeEventListener("click", this.onClick);
      window.removeEventListener("resize", this.setDocumentHeight);
      window.removeEventListener("orientationchange", this.setDocumentHeight);
      window.removeEventListener("scroll", this.onScroll);
    },
    showHoverMenu() {
      if (this.hoverMenu) {
        this.isMenuActive = true;
      }
    },
    onClick(e) {
      this.nestedSubmenu = null;
      const target = e.target || e.srcElement;
      if ((!this.$refs.headerMenu || !this.$refs.headerMenu.contains(target)) && (!this.$refs.sidebar || !this.$refs.sidebar.contains(target))) {
        this.hideHoverMenu();
      } else if (
        !["submenu1", "submenu2", "submenu3", "activeSubmenu", "howMenu", "knowledgeMenu", "storyMenu", "submenu1Mobile", "submenu2Mobile", "submenu3Mobile"].some(
          (ref) => this.$refs[ref] && this.$refs[ref].contains(target)
        )
      ) {
        this.activeSubmenu = null;
      }
      if (this.$refs.moreActions && !this.$refs.moreActions.contains(target)) {
        EventBus.$emit(EventTypes.MORE_ACTIONS, false);
      }
      if (!this.isIn(target, ['userMenu', 'userName'])) {
        this.showUserMenu = false;
      }
      if (!this.isIn(target, ['campaignMenu', 'submenu0Mobile'])) {
        this.showCampaignMenu = false;
      }
    },
    onScroll() {
      this.headerScrolled = false;
      const scrollTop = Math.max(window.pageYOffset || document.documentElement.scrollTop, 0);
      const visible = this.headerStyles.findIndex(s => s && s.display != 'none');
      if (visible != -1 && this.$refs[this.headerRefs[visible]]) {
        const headerBottom = this.$refs[this.headerRefs[visible]].getBoundingClientRect().bottom;
        const bgElem = document.querySelector('.background-scroll');
        if (bgElem) {
          const bgBottom = bgElem.getBoundingClientRect().bottom;
          this.headerScrolled = bgBottom < headerBottom;
        }
      }
      if (this.hideHeaderOnScroll) {
        this.headerHidden = this.scrollTop < scrollTop;
      } else {
        this.headerHidden = false;
      }
      if (this.headerHidden) {
        this.activeSubmenu = null;
      }
      this.scrollTop = scrollTop;
    },
    isIn(target, refs) {
      if (!Array.isArray(refs)) {
        refs = [refs];
      }
      return refs.some(r => this.$refs[r] && this.$refs[r].contains(target));
    },
    hideHoverMenu() {
      this.isMenuActive = false;
      this.activeSubmenu = null;

      if (inIframe()) {
        postMessageToParent({
          type: EventTypes.EMIT,
          payload: { type: EventTypes.HIDE_HOVER_MENU, data: true },
        });
      }
    },
    fetchCart() {
      this.loading.cart = true;
      this.$axios
        .get(`${process.env.VUE_APP_API_URL}/api/getCart`)
        .then((response) => {
          if (response.data) {
            localStorage.setItem("cartId", response.data.cartID);
          }
          this.loading.cart = false;
        })
        .catch((err) => {
          this.loading.cart = false;
          console.log(err);
          this.$toast.error("Unable to fetch cart id");
        });
    },
    toggleSubmenu(menu, force) {
      if (!force && menu == this.activeSubmenu) {
        this.activeSubmenu = null;
      } else {
        this.activeSubmenu = menu;
      }
    },
    setDesignHelp(value) {
      this.showDesignHelp = value;
    },
    setRequestQuote(value) {
      this.showQuotePopup = value;
    },
    setDocumentHeight: debounce(function() {
      if (document.documentElement) {
        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight / 100}px`
        );
      }
    }, 100),
    setLoginPopup(value) {
      this.showLoginPopup = value;
    },
    setGlobalLoader(value) {
      this.globalLoader = value;
    },
    setGlobalMessage(value) {
      this.globalMessage = value;
    },
    toggleUserMenu() {
      if (this.isAuthenticated) {
        this.showUserMenu = !this.showUserMenu;
      } else {
        this.setLoginPopup(true);
        this.showSidebar = false;
      }
    },
    toggleCampaignMenu() {
      // if (this.isAuthenticated) {
      this.showCampaignMenu = !this.showCampaignMenu;
      // } else {
      //   this.setLoginPopup(true);
      //   this.showSidebar = false;
      // }
    },
    // onSearch() {
    //   this.$router.push('/products?q=' + this.searchTerm);
    // }
    onSearch(term) {
      if (this.$refs.search) {
        this.$refs.search.blur();
      }
      if (typeof term != "string") {
        term = encodeURIComponent(this.searchTerm);
      }
      if (term.length < 4) {
        this.$toast.error("Please enter at least 4 characters to search");
        return Promise.resolve(false);
      }

      if (this.$refs.headerEmail) {
        this.$refs.headerEmail.submit();
      }
    },
    hasAlphabets(text) {
      return /[a-zA-Z]+/.test((text || "").toString());
    },
    onSearchChanged(event) {
      this.selectedAddress = event.address;
      this.setAutocompleteVisibility(event.target.value);
    },
    setAutocompleteVisibility(term, force_remove = false) {
      const body = document.querySelector("body");
      const should_hide =
        typeof term == "string"
          ? term && !this.hasAlphabets(term)
          : (this.showHeaderTooltip && !this.hasAlphabets(this.searchTerm)) ||
            (this.showPopupTooltip && !this.hasAlphabets(this.popupSearchTerm));
        if (!force_remove && body && should_hide) {
          setTimeout(() => {
            body.classList.add("hide-pac-autocomplete");
          }, 200);
        } else {
          body.classList.remove("hide-pac-autocomplete");
        }
    },
    gotoGallery() {
      this.showEmailPopup(() => this.$router.push('/products'));
    },
    showEmailPopup(action) {
      this.emailPopupAction = action;
      if (this.isAuthenticated || localStorage.getItem('email_submitted')) {
        this.closeEmailPopup(true);
      }
    },
    closeEmailPopup(result) {
      if (result && typeof(this.emailPopupAction) == 'function') {
        this.emailPopupAction();
      }
      this.emailPopupAction = null;
    },
    addRichText(event) {
      if (event) {
        try {
          if (document.getElementsByName(event.name)) {
            console.log(`addRichText: ${event.name} tag already exists`);
          } else {
            const json = JSON.stringify(event.content);
            const el = document.createElement("script");
            el.type = "application/ld+json";
            el.textContent = json;
            el.setAttribute("name", event.name);
            document.head.appendChild(el);
          }
        } catch (e) {
          console.log(e);
          console.log("addRichText: unable to update rich text");
        }
      }
    },
    openDashboard() {
      if (this.isAuthenticated && this.user) {
        if (this.user.isAddressValid && this.user.company && this.user.address) {
          window.open(this.vendastaUrl, '_blank');
        } else {
          if (this.user.company && this.user.address && !this.user.isAddressValid) {
            this.addressTitle = 'We need a valid address to set up the Dashboard';
          } else {
            this.addressTitle = 'We need the following details to enable all the dashboard features';
          }
          this.showAddressPopup = true;
        }
      }
    },
    onAddressUpdated() {
      this.globalLoader = true;
      this.$root.authService.fetchUserDetails().then(() => {
        this.showAddressPopup = false;
        this.openDashboard();
        this.globalLoader = false;
      }).catch(err => {
        console.log(err);
        this.globalLoader = false;
      });
    },
    // updateCampaignName() {
    //   this.cartItem = null;
    //   this.campaignName = '';
    //   this.campaignPlaceholder = 'Enter title';
    //   const cartId = this.$route.query.cart || localStorage.getItem('cartId');
    //   if (cartId && (cartId != this.previousCartId || !this.campaignName)) {
    //     this.loading.cart = true;
    //     this.$root.cartService
    //       .findCart(cartId)
    //       .then(cart => cart || this.$root.cartService.fetchCartById(cartId))
    //       .then(cart => {
    //         const updatedCartId = this.$route.query.cart || localStorage.getItem('cartId');
    //         if (cart && cart.cartID == updatedCartId) {
    //           this.cartItem = cart;
    //           this.campaignName = cart.campaignName || cart.productName;
    //           if (this.campaignName) {
    //             this.campaignPlaceholder = this.campaignName;
    //           }
    //           this.previousCartId = updatedCartId;
    //         }
    //         this.loading.cart = false;
    //       })
    //       .catch(err => {
    //         this.loading.cart = false;
    //         console.log(err);
    //       //   this.$toast.error(
    //       //     "Oops! Something went wrong...",
    //       //     this.updateCartItem.bind(this)
    //       //   );
    //       });
    //   }
    // },
    // onCampaignNameChanged: debounce(function() {
    //   if (this.campaignName && this.campaignName.length > 0) {
    //     const cartId = this.$route.query.cart || localStorage.getItem('cartId');
    //     this.loading.name = true;
    //     const name = this.campaignName;
    //     this.$axios.post("addCartName", {
    //       campaignName: name,
    //       cartID: cartId,
    //     }).then(() => {
    //       if (this.cartItem) {
    //         this.cartItem.campaignName = name;
    //       }
    //       this.loading.name = false;
    //       console.log('campaign name updated');
    //     }).catch(err => {
    //       this.loading.name = false;
    //       console.log(err);
    //       this.$toast.rootError("Something went wrong while saving campaign name...", this.onCampaignNameChanged.bind(this));
    //     });
    //   } else {
    //     this.campaignName = this.campaignPlaceholder;
    //   }
    // }),
    onAuthChanged() {
      if (this.isAuthenticated) {
        if (this.$route.meta.reloadOnLogin && typeof(this.$route.meta.auth) != 'boolean') {
          location.reload();
        }
      }
    },
  },
  computed: {
    isAuthenticated() {
      return this.$root.authService.isAuthenticated;
    },
    user() {
      return this.$root.authService.userData;
    },
    userID() {
      return this.$root.authService.userId;
    },
    name() {
      if (this.$root.authService.userData) {
        let data = this.$root.authService.userData.firstname;
        if (this.$root.authService.userData.lastbame) {
          data += " " + this.$root.authService.userData.lastname;
        }
        return data;
      } else {
        return '';
      }
    },
    isHowShown() {
      return this.activeSubmenu == 'how';
    },
    isKnowledgeShown() {
      return this.activeSubmenu == 'knowledge';
    },
    isStoryShown() {
      return this.activeSubmenu == 'story';
    },
    cartCount() {
      return this.$root.cartService.getCartCount();
    },
    cart() {
      return (this.$root.cartService.cart && Array.isArray(this.$root.cartService.cart.cartContents)) ? this.$root.cartService.cart.cartContents.slice(0, 3) : [];
    },
    appClasses() {
      const classes = { 'hover-menu': this.hoverMenu, 'unsupported-visible': this.showUnsupportedBanner, 'cookie-banner-visible': this.showCookieBanner, 'header-hidden': true };
      switch (this.$route.path) {
        case '/audience-pro':
        case '/cart':
          classes['intercom-position-2'] = true;
          break;
        case '/billing':
        case '/audience':
          classes['intercom-position-1'] = true;
          break;
        default:
          if (this.$route.path.startsWith('/products/')) {
            classes['intercom-position-1'] = true;
          }
      }
      if (Array.isArray(this.$route.meta.appClasses)) {
        this.$route.meta.appClasses.forEach(c => classes[c] = true);
      }
      return classes;
    },
    isAutocompleteDisabled() {
      const term = this.searchTerm;
      return (
        term &&
        term.split(regex.commaSeparated).every((s) => regex.looseZip5.test(s))
      );
    },
    audienceUrl() {
      let url = "/audience-pro";
      let location;
      if (this.selectedAddress && this.selectedAddress.geometry && this.selectedAddress.geometry.location) {
        location = this.selectedAddress.geometry.location.lat() + ' ' + this.selectedAddress.geometry.location.lng();
      }
      const q = this.searchTerm || location;
      if (q) {
        url += "?q=" + q;
        if (location) {
          url += "&location=" + location;
        }
      } else {
        url = '/audience';
      }
      return url;
    },
    showLinkList() {
      return typeof(this.showHeaderLinks) == "boolean" ? this.showHeaderLinks : this.showHeaderLinks == 'all';
    },
    isCampaignRoute() {
      return ['/audience', '/audience-pro', '/design', '/editor', '/products'].some(url => this.$route.path.startsWith(url));
    },
  },
  watch: {
    $route: function() {
      this.updateState();
    },
  },
};
</script>
<style scoped>
.header-hidden .header {
  transform: translateY(-100%);
}

.header {
  z-index: 5;
  transition: all 0.25s ease;
}

.header.header-submenu-open {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header-desktop {
  padding: 1.25em 3.125em;
  align-items: center;
  width: 100%;
  animation: opacity 0.25s ease;
}

.header-desktop .nav-links,
.header-desktop .icon-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.header-desktop .nav-links li {
  font-size: 1.25em;
  margin-right: 1.5em;
  font-weight: 500;
  position: relative;
}

.header-desktop .nav-links li > *,
.header-desktop .icon-links li svg *,
.header-desktop .icon-links li svg * {
  transition: 0.2s all ease;
}

.header-desktop .nav-links li:last-child {
  margin-right: 0;
}

.header-desktop .nav-links > li > a::before {
  width: 0;
  position: absolute;
  top: 110%;
  left: 50%;
  right: 0;
  border-radius: 360px;
  height: 3px;
  background-color: currentColor;
  content: "";
  transform: translateX(-50%);
  transition: width 0.3s ease 0s;
  overflow: hidden;
}

.header-desktop .nav-links > li:hover > a::before,
.header-desktop .nav-links > li.active > a::before {
  width: 100%;
}

.header-desktop .nav-links li:hover,
.header-desktop .nav-links li.active,
.header-desktop .icon-links li:hover {
  color: var(--color-primary);
}

.header-desktop .icon-links > li + li {
  margin-left: 2em;
}

.header-desktop .icon-links svg {
  height: 1.6875em;
  width: auto;
  max-width: 1.875em;
}

.header-desktop .search-container {
  margin-right: 1em;
}

.header-desktop .get-started-btn {
  margin-right: 1.5em;
  /* max-width: 21em; */
}

.search-control {
  padding-right: 1.45em;
  background-image: none;
  width: 11em;
}

.header-mobile {
  padding: 0.8333em 1.666em;
  width: 100%;
  align-items: center;
}

/* .sidebar {
  width: 20em;
  box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.02);
  flex-direction: column;
  overflow: auto;
} */

.sidebar .header-mobile {
  padding-bottom: 0.6666em;
  background-color: #f8fdff;
  flex-wrap: wrap;
}

.sidebar .header-mobile .username {
  font-size: 1.25em;
  font-weight: 500;
  margin-top: 2.533em;
  width: 100%;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
  text-align: left;
}

.sidebar .header-mobile .username img,
.sidebar  .navigation-links li img {
  transition: 0.15s transform ease-out;
  height: 0.5em;
  width: auto;
  max-width: 1em;
  vertical-align: middle;
  float: right;
  margin: 0.5em 0 0.25em 1em;
}

.sidebar .header-mobile .username.active img,
.sidebar .navigation-links a.active img {
  transform: rotate(180deg);
}

.sidebar .navigation-links {
  padding: 2.5em 2.0833em;
  flex: 1 1 0px;
  list-style-type: none;
  margin: 0;
}

.sidebar .navigation-links > li,
.sidebar .footer-links li {
  font-size: 1.25em;
}

.sidebar .footer-links {
  padding: 1.6666em 2.0833em;
  list-style-type: none;
  margin: 0;
}

.sidebar .footer-links li {
  font-weight: 300;
}

.sidebar .navigation-links > li + li,
.sidebar .footer-links li + li {
  margin-top: 10px;
}

.sidebar .navigation-links li a,
.sidebar .footer-links li a {
  width: 100%;
}

ul.header-submenu {
  position: fixed;
  list-style-type: none;
  display: flex;
  left: 0;
  right: 0;
  z-index: 4;
  background-color: white;
  padding: 0.5em 1em 0.5em 10em;
  font-size: 1.25em;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.02);
}

ul.header-submenu > li,
ul.header-submenu > li > a {
  transition: all 0.25s ease;
}

ul.header-submenu li a {
  padding: 0.5em 1em;
  border-radius: 360px;
  position: relative;
}

ul.header-submenu > li > a::before {
  width: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;
  border-radius: 360px;
  height: 3px;
  background-color: currentColor;
  content: "";
  transform: translateX(-50%);
  transition: width 0.3s ease 0s;
  overflow: hidden;
}

ul.header-submenu > li:hover > a::before {
  width: calc(100% - 2em);
}

ul.header-submenu > li:hover > a.caret-down::before {
  width: calc(100% - 1.3em);
  transform: translateX(-45%);
}

ul.header-submenu > li:hover > a {
  color: var(--color-primary) !important;
}

ul.header-submenu ul.header-submenu {
  font-size: 1em;
  transition: 0.25s ease all;
  transform: translateY(0);
  z-index: -1;
}

ul.header-submenu > li:not(:hover):not(:focus-within) > ul.header-submenu {
  /* display: none; */
  transform: translateY(-100%);
  opacity: 0;
  pointer-events: none;
}

.user-menu,
.nav-menu,
.dropdown-submenu {
  max-height: 0;
  overflow: hidden;
  transition: 0.25s max-height ease;
  list-style: none;
  padding: 0;
  width: 100%;
}

.user-menu li,
.dropdown-submenu li {
  padding: 0.5em 0;
}

.user-menu li svg,
.footer-links li svg {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.3em;
}

.user-menu li path,
.footer-links li path {
  fill: currentColor;
}

.nav-menu li {
  padding: 0.3em 0;
  font-weight: 400;
  font-size: 0.8em;
}

.user-menu.active,
.nav-menu.active,
.dropdown-submenu.active {
  max-height: 17em;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
}

.app .global-loader {
  z-index: 1060;
}

.app.unsupported-active {
  margin-top: 0;
  transition: 0.5s margin-top ease;
}

.app .main-view {
  flex: 1 1 0px;
  min-width: 0;
}

.unsupported-banner {
  width: 100%;
  background-color: #FF4653;
  color: white;
  padding: 1em;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  font-size: 1.25em;
}

.unsupported-banner .browsers {
  list-style: none;
  padding: 0;
  display: flex;
}

.cookie-banner {
  background-color: var(--color-medium-purple);
  display: flex;
  padding: 1em 2.5em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  align-items: center;
  z-index: 1051;
  font-size: 1.25em;
  justify-content: space-between;
  /* color: white; */
  box-shadow: 0 0 5px 10px rgba(0, 0, 0, 0.05);
}

.cart-link {
  position: relative;
}

.cart-dropdown {
  position: absolute;
  right: 0;
  top: 100%;
  width: 30em;
  /* max-height: 20em; */
  background: white;
  box-shadow: 0 0 5px 10px rgba(0, 0, 0, 0.02);
  border-radius: 0.5em;
  padding: 0.8em 0 0;
  display: none;
  color: #4C5454;
  overflow: auto;
}

.cart-dropdown .view-all {
  width: 100%;
  padding: 1em;
  background: #eee;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.25s ease;
  font-weight: 700;
}

.cart-dropdown .view-all:hover {
  background: #ccc;
}

.cart-dropdown ol {
  margin: 0;
  list-style-type: none;
  padding: 0 0.5em;
}

.cart-dropdown ol li {
  padding: 1em 1em;
  color: inherit;
  display: flex;
  align-items: flex-start;
}

.cart-dropdown .thumbnail-container {
  text-align: center;
  width: 5em;
  height: 3em;
  margin-right: 2em;
  flex: 0 0 auto;
}

.cart-dropdown .thumbnail-container img {
  border: 1px solid rgba(76, 84, 84, 0.2);
}

.cart-dropdown ol li:hover {
  color: inherit !important;
}

.cart-dropdown ol li + li {
  border-top: 1px solid rgba(76, 84, 84, 0.2);
}

.cart-dropdown nav > a {
  color: gray;
}

.cart-dropdown nav > a + a::before {
  content: '\25CF';
  margin: 0 0.5em;
}

.cart-link:hover .cart-dropdown {
  display: block;
  z-index: 2;
}

.inline-links {
  overflow: hidden;
  max-height: 0;
  margin: 0;
  list-style: none;
  transition: 0.25s all ease-in-out;
  display: flex;
  padding: 0;
}

li:hover > .inline-links {
  max-height: 3em;
}

.caret-down,
.header-submenu .caret-down {
  padding-right: 1.7em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7'%3E%3Cpath d='M8.246,9.308l4.166,4.125,4.166-4.125a1.077,1.077,0,0,1,1.514,0,1.051,1.051,0,0,1,0,1.5l-4.929,4.879a1.077,1.077,0,0,1-1.514,0L6.722,10.807a1.051,1.051,0,0,1,0-1.5A1.1,1.1,0,0,1,8.246,9.308Z' transform='translate(-6.407 -8.997)' fill='%234c5454'/%3E%3C/svg%3E");
  background-position: right 0.5em center;
  background-size: 0.8em 0.47em;
  background-repeat: no-repeat;
}

.caret-down:hover,
li.active > .caret-down,
li:hover > .caret-down {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7'%3E%3Cpath d='M8.246,9.308l4.166,4.125,4.166-4.125a1.077,1.077,0,0,1,1.514,0,1.051,1.051,0,0,1,0,1.5l-4.929,4.879a1.077,1.077,0,0,1-1.514,0L6.722,10.807a1.051,1.051,0,0,1,0-1.5A1.1,1.1,0,0,1,8.246,9.308Z' transform='translate(-6.407 -8.997)' fill='%230133ff'/%3E%3C/svg%3E");
}

.campaign-name {
  margin-right: 1em;
}

@media (max-width: 1439px) {
  .header-desktop {
    padding-left: 2.625em;
    padding-right: 2.625em;
  }

  .header-desktop .nav-links li {
    margin-right: 1.5em;
  }

  .header-desktop .icon-links > li + li {
    margin-left: 2em;
  }
}

@media (max-width: 1199px) {
  .header-desktop {
    padding-left: 2.125em;
    padding-right: 2.125em;
    font-size: 0.9em;
  }

  .header-desktop .nav-links li {
    margin-right: 1.5em;
  }

  .header-desktop .icon-links > li + li {
    margin-left: 2em;
  }
}

@media (max-width: 991px) {
  ul.header-submenu {
    display: none;
  }
}

@media (max-width: 575px) {
  .cookie-banner {
    padding: 1em;
    font-size: 1em;
  }
}
</style>

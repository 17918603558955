import axios from "axios";

class AnalyticsService {
  constructor() {
    this.endpoint = "https://api.beaconstac.com/reporting/2.0/";
    this.organizationId = Number(process.env.VUE_APP_BEACONSTAC_ORG);

    this.axios = axios.create();

    this.axios.interceptors.request.use((req) => {
      req.headers.Authorization = `Token ${process.env.VUE_APP_BEACONSTAC_KEY}`;
      if (!req.params) {
        req.params = {};
      }
      if (!req.params.organization) {
        req.params.organization = this.organizationId;
      }
      return req;
    });
  }

  getOverview(from, to, interval, product_ids = []) {
    return this.getAnalytics('getOverview', { product_id: product_ids, from, to, interval });
  }

  getPeriodOverview(from, to, interval, product_ids = []) {
    return this.getAnalytics('getPeriodOverview', { product_id: product_ids, from, to, interval });
  }

  getTimeOfDayDistribution(from, to, interval, product_ids = []) {
    return this.getAnalytics('getTimeOfDayDistribution', { product_id: product_ids, from, to, interval });
  }

  getOsDistribution(from, to, interval, product_ids = []) {
    return this.getAnalytics('getOsDistribution', { product_id: product_ids, from, to, interval });
  }

  getVisitorDistribution(from, to, interval, product_ids = []) {
    return this.getAnalytics('getVisitorDistribution', { product_id: product_ids, from, to, interval });
  }
  
  getCityDistribution(from, to, interval, product_ids = []) {
    return this.getAnalytics('getCityDistribution', { product_id: product_ids, from, to, interval });
  }
  
  getLocationDetail(from, to, product_ids = []) {
    return this.getAnalytics('getLocationDetail', { product_id: product_ids, from, to });
  }

  getIndividualProductPerformance(product_id, from, to, interval) {
    return this.getAnalytics('getPerformance', { product_id, from, to, interval });
  }

  getAnalytics(method, data) {
    if (!data.timezone) {
      data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    if (!data.product_type) {
      data.product_type = 'qr';
    }
    if (!data.interval) {
      const diff = data.to - data.from;
      if (diff >= 157680000000) {
        // 5 yrs
        data.interval = '1y';
      } else if (diff >= 12960000000) {
        // 5 months
        data.interval = '1M';
      } else if (diff >= 5184000000) {
        // 2 months
        data.interval = '1w';
      } else if (diff >= 86400000) {
        // 2 days
        data.interval = '1d';
      } else if (diff >= 18000000) {
        // 5 hours
        data.interval = '1h';
      } else if (diff < 18000000) {
        data.interval = '30m';
      } else {
        data.interval = '1d';
      }
    }
    data.from = data.from.toString();
    data.to = data.to.toString();
    return this.axios.post(this.endpoint, data, { params: { method: `Products.${method}`}});
  }
}

export const analyticsService = new AnalyticsService();
import axios from "axios";
import { authService } from "./auth.service";
// import { EventBus, EventTypes } from "@/event-bus";

class YotpoService {
  constructor() {
    // this.guid = process.env.VUE_APP_YOTPO_GUID;
    this.appKey = process.env.VUE_APP_YOTPO_APP_KEY;
    this.isLoaded = false;
    this.isReady = false;
    this.queue = [];
    /* this.initialize(); */
    // if (authService.isAuthenticated) {
    //   this.initializeAuth();
    // } else {
    //   EventBus.$on(EventTypes.AUTH_CHANGED, value => value && this.initializeAuth());
    // }
    // this.apiKey = process.env.VUE_APP_YOTPO_API_KEY;
    // this.axios = this.authenticate();
  }
  
  load(url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.addEventListener("load", () => resolve(true));
      script.addEventListener("error", reject);
      script.src = url;
      document.head.appendChild(script);
    });
  }

  initialize() {
    return Promise.all([
      this.load(`//staticw2.yotpo.com/${this.appKey}/widget.js`),
      //this.load(`https://cdn-widgetsrepository.yotpo.com/v1/loader/${this.guid}`)
    ]).then(() => {
      this.yotpo = new window.Yotpo.API(window.yotpo);
    });
  }

  initializeAuth() {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        resolve(true);
      } else {
        this.waitForUserData().then(() => {
          document.addEventListener('swell:setup', () => {
            this.swellAPI = window.swellAPI;
            this.isReady = true;
            this.clearQueue();
          });
          return this.load(`https://cdn-loyalty.yotpo.com/loader/${this.guid}.js`);
        }).then(() => {
          this.isLoaded = true;
          this.clearQueue();
          resolve(true);
        }).catch(reject);
      }
    });
  }

  async waitForUserData() {
    if (authService.isAuthenticated) {
      if (!authService.userData) {
        await authService.fetchUserDetails();
      }
    } else {
      // throw "yotpo: can only be initialized after login!";
    }
  }

  enqueue(task) {
    if (this.isLoaded && this.isReady) {
      task();
    } else {
      this.queue.push(task);
    }
  }

  clearQueue() {
    for (let i = this.queue.length; i > 0; --i) {
      setTimeout(() => {
        this.enqueue(this.queue.shift());
      });
    }
  }

  initialization() {
    return new Promise((resolve, reject) => {
      this.enqueue(() => {
        try {
          resolve(true);
        } catch (err) {
          reject(err);
        }
      })
    });
  }

  async getCustomerDetails() {
    await this.initialization();
    await this.refreshCustomerDetails();
    return this.swellAPI.getCustomerDetails();
  }

  refreshCustomerDetails() {
    return new Promise((resolve, reject) => {
      this.initialization()
      .then(() => this.swellAPI.refreshCustomerDetails(resolve))
      .catch(reject);
    });
  }

  refreshWidgets() {
    if (this.isLoaded) {
      // refresh is only needed if yotpo has been already loaded
      this.yotpo.refreshWidgets();
    }
  }

  // fetchSiteReviews() {
  //   return axios.get(`https://api-cdn.yotpo.com/v1/widget/${this.appKey}/products/yotpo_site_reviews/reviews.json`);
  // }

  fetchSiteReviews() {
    return axios.post(`https://api-cdn.yotpo.com/v1/reviews/${this.appKey}/filter.json`, {
      domain_key: "yotpo_site_reviews",
      sortings: [
        {
          sort_by: "score",
          ascending: false
        },
        {
          sort_by: "date",
          ascending: false
        }
      ],
      per_page: 20
    });
  }

  // Following APIs should only be called on the backend

  authenticate() {
    const client = axios.create({
      baseURL: 'https://loyalty.yotpo.com/api/v2/'
    });
    client.interceptors.request.use(req => {
      if (!req.data) {
        req.data = {};
      }
      req.headers['x-guid'] = this.guid;
      req.headers['x-api-key'] = this.apiKey;

      return req;
    });
    return client;
  }

  /*
  order: total_amount_cents, coupon_code, discound_amount_cents, tags
   */
  createOrder(order) {
    return this.getCustomerDetails().then(customer => {
      return this.axios.post('orders', {
        ...order,
        currency_code: 'USD',
        customer_id: authService.userId,
        customer_email: authService.userData.email,
        customer_object: customer,
        ignore_ip_ua: true // set to false in prod and send ip and user agent
      });
    });
  }

  /*
  user: id, email, first_name, last_name, 
  */
  createCustomer(user) {
    return this.axios.post('customers', user);
  }

  /*
  data: rating, content
  */
  createReview(data) {
    const title = data.content.substring(0, 50);
    return axios.post('https://api.yotpo.com/v1/widget/reviews', {
      appkey: this.appKey,
      domain: process.env.VUE_APP_WORDPRESS_URL,
      sku: "yotpo_site_reviews",
      product_title: "Site Reviews",
      product_url: process.env.VUE_APP_WORDPRESS_URL,
      display_name: this.getName(),
      email: authService.userData.email,
      review_content: data.content,
      review_title: title,
      review_score: data.rating
    });
  }

  getName() {
    if (authService.userData) {
      let data = authService.userData.firstname;
      if (authService.userData.lastbame) {
        data += " " + authService.userData.lastname;
      }
      return data;
    } else {
      return '';
    }
  }
}

export const yotpoService = new YotpoService();
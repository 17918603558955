<template>
  <div :class="{'loader-container': true, 'loader-overlay': isAbsolute, [type]: true, 'has-message': showMessage}" @click.prevent.stop>
    <!-- <div class="mk-spinner-wrap" v-if="isIndefinite">
      <div class="mk-spinner-doublecircle"></div>
    </div> -->
    <div class="loader">
      <div class="shadow"></div>
      <img src="/images/favicon-dark.png" class="ball" />
    </div>
    <div class="position-relative message-box">
      <div class="message-container">
        <transition name="slide-fade" appear="">
          <p v-if="showMessage" class="loading-message">{{visibleMessage}}</p>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import LOADING_MESSAGES from "@/assets/json/quotes.json";

export default {
  name: "Loader",
  props: {
    isIndefinite: {
      type: Boolean,
      default: true
    },
    isAbsolute: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: ""
    },
    showDelay: {
      type: Boolean,
      default: false
    },
    delay: {
      type: Number,
      default: 6
    },
    intervalDelay: {
      type: Number,
      default: 10
    },
    type: {
      type: String,
      default: 'normal'
    }
  },
  mounted() {
    if (this.showDelay) {
      this.timeout = setTimeout(() => this.updateMessage(), this.delay * 1000);
    }
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  data() {
    return {
      timeout: null,
      showMessage: false,
      messageIndex: -1
    }
  },
  methods: {
    updateMessage() {
      if (!this.message) {
        let index;
        do {
          index = Math.floor(Math.random() * LOADING_MESSAGES.length);
        } while (index == this.messageIndex);
        this.messageIndex = index;
      }
      this.showMessage = this.showDelay;
      this.timeout = setTimeout(() => this.updateMessage(), this.intervalDelay * 1000);
    }
  },
  computed: {
    visibleMessage() {
      return this.message || LOADING_MESSAGES[this.messageIndex];
    }
  }
}
</script>
<style scoped>
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.message-container {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  /* white-space: nowrap; */
  text-align: center;
  padding: 0.3em;
  font-weight: 600;
  font-size: 1.25em;
}

.has-message .message-box,
.has-message .message-container {
  width: 100%;
}

.loader-overlay .message-container {
  width: 100%;
  color: white;
  text-shadow: 0 0 2px black;
}

@keyframes expand {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.inside.loader-overlay {
  border-radius: inherit;
  padding: 2px;
  background-color: white;
  background-clip: content-box;
  overflow: hidden;
  animation: 0.5s expand ease-in both;
}

@keyframes bounce {
  from {
    transform: scaleY(1.1) translateY(-50%)
  }
}

@keyframes shadow {
  from {
    transform: scale(0.5);
  }
}

.loader {
  position: relative;
}

.loader .ball {
  /* padding-left: 0.25em; */
  width: 2em;
  height: auto;
  /* border-radius: 50%; */
  animation-name: bounce;
  transform: scaleX(1.3);
  transform-origin: center;
}

.loader .shadow {
  content: '';
  position: absolute;
  top: 90%;
  left: 0%;
  width: 100%;
  height: 20%;
  border-radius: 50%;
  background: gray;
  transform: scale(1.3);
  animation-name: shadow;
}

.loader .ball,
.loader .shadow {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in;
}

/* .mk-spinner-wrap {
  min-height: 3em;
  width: 100%;
  position: relative;
}
@-webkit-keyframes a {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes a {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.mk-spinner-doublecircle {
  background: transparent;
  animation: a 2s ease-in-out infinite;
}
.mk-spinner-doublecircle,
.mk-spinner-doublecircle:after {
  border: 0.4em solid;
  border-radius: 50%;
  content: "";
  display: inline-block;
  position: absolute;
  width: 3em;
  height: 3em;
  top: 50%;
  margin-top: -1.5em;
  left: 50%;
  margin-left: -1.5em;
  z-index: 1;
}
.mk-spinner-doublecircle {
  border-color: rgba(21, 219, 150, 0.7) rgba(0, 0, 0, 0.07);
}
.mk-spinner-doublecircle:after {
  border-color: transparent rgba(13, 26, 163, 0.7);
  animation: a 4s ease-in-out infinite;
} */
</style>
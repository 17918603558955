export class IDBStorageProvider {
  constructor(dbName, schema, version) {
    this.dbName = dbName;
    this.initializePromise = this.initialize(dbName, schema, version);
  }

  initialize(dbName, schema, version) {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(dbName, version);

      request.onerror = event => {
        console.log(event);
        reject(event);
      };
      request.onupgradeneeded = event => {
        this.db = event.target.result;
        schema.stores.forEach(store => {
          this.createStore(store.name, store.key);
        });
        resolve(true);
      };
      request.onsuccess = event => {
        this.db = event.target.result;
        resolve(true);
      }
    });
  }

  createStore(name, key) {
    const objectStore = this.db.createObjectStore(name, { keyPath: key });
    objectStore.createIndex(key, key, { unique: true });
  }

  insert(store, item) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(store, 'readwrite');
      transaction.onerror = reject;
      const objectStore = transaction.objectStore(store);
      const request = objectStore.put({ routeID: item.routeID, item, last_updated: new Date().getTime() });
      request.onsuccess = resolve;
      request.onerror = reject;
    });
  }

  fetch(store, key, fresh_timeout) {
    return new Promise((resolve, reject) => {
      this.initializePromise.then(() => {
        const transaction = this.db.transaction(store, 'readonly');
        transaction.onerror = reject;
        const objectStore = transaction.objectStore(store);
        const request = objectStore.get(key);
        request.onsuccess = () => {
          const row = request.result;
          if (row && fresh_timeout > 0 && new Date().getTime() - row.last_updated <= fresh_timeout) {
            resolve(row.item);
          } else {
            this.delete(store, key).then(() => resolve(null)).catch(reject);
          }
        };
        request.onerror = reject;
      }).catch(reject)
    });
  }

  delete(store, key) {
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction(store, 'readwrite');
      transaction.onerror = reject;
      const objectStore = transaction.objectStore(store);
      const request = objectStore.delete(key);
      request.onsuccess = () => resolve(true);
      request.onerror = reject;
    });
  }

  deleteDatabase() {
    return new Promise((resolve, reject) => {
      if (this.db) {
        this.db.close();
      }
      const request = window.indexedDB.deleteDatabase(this.dbName);

      request.onsuccess = () => resolve(true);
      request.onerror = reject;
    });
  }
}
import axios from 'axios'
import { ALL_PREDICTION_MODEL_TYPES } from '../utils';

export function getRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      // redirect: "/audience",
      // beforeEnter: () => window.location.href = process.env.VUE_APP_WORDPRESS_URL,
    },
    // {
    //   path: "/",
    //   name: "Home",
    //   component: HomeComponent,
    //   meta: {
    //     appClasses: ["header-green"]
    //   },
    //   alias: "/home",
    // },
    {
      path: "/login",
      name: "Sign In",
      component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: {
        title: "Sign In",
      },
    },
    {
      path: "/oauth-login",
      name: "OAuth 2.0 Sign In",
      component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
      meta: {
        title: "Sign In",
      },
      props: {
        oauth: true,
      },
    },
    {
      path: "/quote",
      name: "Custom Quote Request",
      component: () => import(/* webpackChunkName: "custom-quote" */ "../views/CustomQuote.vue"),
      meta: {
        title: "Custom Quote Request",
      },
    },
    {
      path: "/service",
      name: "Design Request",
      component: () => import(/* webpackChunkName: "design-help" */ "../views/DesignHelp.vue"),
      meta: {
        title: "Design Request",
      },
    },
    {
      path: "/audience-pro",
      name: "Advanced Audience Selection",
      component: () =>
        import(/* webpackChunkName: "audience-pro" */ "../views/AudienceSelection.vue"),
      meta: {
        title: "Advanced Audience Selection",
        auth: true,
      },
    },
    {
      path: "/audience",
      name: "Audience Selection",
      component: () =>
        import(/* webpackChunkName: "audience" */ "../views/AudienceGuided.vue"),
      meta: {
        title: "Audience Selection",
        auth: true,
      },
    },
    {
      path: "/editor",
      name: "Editor",
      component: () =>
        import(/* webpackChunkName: "editor" */ "../views/DesignTool/Editor.vue"),
      meta: {
        title: "Design Studio",
        sitemap: {
          ignoreRoute: true
        },
        auth: true,
      },
    },
    {
      path: "/editor-demo",
      name: "Editor Demo",
      component: () =>
        import(/* webpackChunkName: "editor" */ "../views/DesignTool/Editor.vue"),
      meta: {
        title: "Try our Design Studio",
        sitemap: {
          ignoreRoute: true
        },
        auth: true,
      },
      props: {
        demo: true
      }
    },
    {
      path: "/design",
      name: "Design Tool",
      component: () =>
        import(
          /* webpackChunkName: "design" */ "../views/DesignTool/DesignTool.vue"
        ),
      meta: {
        title: "Design Studio",
        auth: true,
      },
    },
    // {
    //   path: "/demo",
    //   name: "Design Tool Demo",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "design" */ "../views/DesignTool/DesignTool.vue"
    //     ),
    //   meta: {
    //     title: "Try our Design Studio",
    //     sitemap: {
    //       ignoreRoute: true
    //     }
    //   },
    //   props: {
    //     demo: true
    //   }
    // },
    {
      path: "/account",
      meta: {
        auth: true,
        title: "My Account",
        sitemap: {
          ignoreRoute: true
        },
      },
      component: () =>
        import(/* webpackChunkName: "account" */ "../views/Account/Account.vue"),
      children: [
        {
          path: "",
          redirect: "personal-details",
        },
        {
          path: "active-orders",
          name: "Active Orders",
          component: () =>
            import(
              /* webpackChunkName: "active-orders" */ "../views/Account/ActiveOrders.vue"
            ),
          meta: {
            title: "Active Orders",
          },
        },
        {
          path: 'active-orders/:id',
          redirect: 'active-orders',
        },
        {
          path: "past-orders",
          name: "Past Orders",
          component: () =>
            import(
              /* webpackChunkName: "past-orders" */ "../views/Account/PastOrders.vue"
            ),
          meta: {
            title: "Past Orders",
          },
        },
        {
          path: 'past-orders/:id',
          redirect: 'past-orders',
        },
        // {
        //   path: "saved-orders",
        //   name: "Saved Orders",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "saved-orders" */ "../views/Account/SavedOrders.vue"
        //     ),
        //   meta: {
        //     title: "Saved Orders",
        //   },
        // },
        {
          path: "change-password",
          name: "Change Password",
          component: () =>
            import(
              /* webpackChunkName: "change-password" */ "../views/Account/ChangePassword.vue"
            ),
          meta: {
            title: "Change Password",
          },
        },
        {
          path: "personal-details",
          name: "Personal Details",
          component: () =>
            import(
              /* webpackChunkName: "personal-details" */ "../views/Account/PersonalDetails.vue"
            ),
          meta: {
            title: "Personal Details",
          },
        },{
          path: "lookalike-audiences",
          name: "Lookalike Audiences",
          component: () =>
            import(
              /* webpackChunkName: "lookalike-audiences" */ "../views/Account/LookalikeAudiences.vue"
            ),
          meta: {
            title: "Lookalike Audiences",
          },
        },
        {
          path: "saved-designs",
          name: "Saved Designs",
          component: () =>
            import(
              /* webpackChunkName: "saved-designs" */ "../views/Account/SavedDesigns.vue"
            ),
          meta: {
            title: "Saved Designs",
          },
        },
        {
          path: "saved-audiences",
          name: "Saved Audiences",
          component: () =>
            import(
              /* webpackChunkName: "saved-routes" */ "../views/Account/SavedRoutes.vue"
            ),
          meta: {
            title: "Saved Audiences",
          },
        },
        {
          path: "analytics",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Analytics",
              component: () =>
                import(
                  /* webpackChunkName: "analytics" */ "../views/Analytics.vue"
                ),
              meta: {
                title: "Analytics",
              },
            },
            {
              path: ":distribution",
              name: "Distribution Analytics",
              component: () =>
                import(
                  /* webpackChunkName: "distribution-analytics" */ "../views/DistributionAnalytics.vue"
                ),
              meta: {
                title: "Distribution Analytics",
              },
            }
          ]
        },
        // {
        //   path: "refer",
        //   name: "Referral Programme",
        //   component: () =>
        //     import(
        //       /* webpackChunkName: "refer" */ "../views/Account/Referral.vue"
        //     ),
        //   meta: {
        //     title: "Referral Programme",
        //   },
        // },
      ],
    },
    {
      path: "/products",
      component: () =>
        import(/* webpackChunkName: "products" */ "../views/Products.vue"),
      meta: {
        title: "Gallery",
        auth: true,
      },
      children: [
        {
          path: "",
          name: "Products",
          meta: {
            title: "Gallery",
            hideHeaderLinks: 'home-only',
            hideHeaderOnScroll: true
          },
        },
        {
          path: ":id",
          name: "Product Details",
          meta: {
            title: "Product Details",
            sitemap: {
              slugs: async () => await getAllProductIds()
            }
          },
        },
      ],
    },
    // {
    //   path: "/cart",
    //   name: "Cart",
    //   component: () => import(/* webpackChunkName: "cart" */ "../views/Cart.vue"),
    //   meta: {
    //     title: "Cart",
    //   },
    // },
    {
      path: "/cart",
      redirect: "/campaigns",
    },
    {
      path: "/register",
      name: "Sign Up",
      meta: {
        auth: false,
        title: "Sign Up",
      },
      component: () =>
        import(/* webpackChunkName: "register2" */ "../views/Register2.vue"),
    },
    {
      path: "/schedule",
      name: "Scheduling",
      meta: {
        auth: true,
        title: "Schedule My Campaign",
      },
      component: () =>
        import(/* webpackChunkName: "schedule" */ "../views/ScheduleCampaign.vue"),
    },
    {
      path: "/billing",
      name: "Billing",
      meta: {
        auth: true,
        title: "Billing",
      },
      component: () =>
        import(/* webpackChunkName: "billing" */ "../views/Billing.vue"),
    },
    {
      path: "/transaction-status/:status",
      name: "Transaction Status",
      meta: {
        auth: true,
        title: "Transaction Status",
        sitemap: {
          ignoreRoute: true
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "transaction-status" */ "../views/TransactionStatus.vue"
        ),
    },
    // {
    //   path: "/blogs",
    //   component: {
    //     render(c) {
    //       return c("router-view");
    //     },
    //   },
    //   children: [
    //     {
    //       path: "",
    //       name: "Blogs",
    //       component: () =>
    //         import(/* webpackChunkName: "blogs" */ "../views/Blogs.vue"),
    //       meta: {
    //         title: "Blogs",
    //         appClasses: ["header-red"]
    //       },
    //     },
    //     {
    //       path: "1",
    //       name: "1",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-1" */ "../views/Blogs/1.vue"),
    //       meta: {
    //         title: "3 Direct Mail Stats Every Small Business Owner Should See",
    //       },
    //     },
    //     {
    //       path: "2",
    //       name: "2",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-2" */ "../views/Blogs/2.vue"),
    //       meta: {
    //         title: "3 Easy Ways to Target Your Ideal Audience With EDDM®",
    //       },
    //     },
    //     {
    //       path: "3",
    //       name: "3",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-3" */ "../views/Blogs/3.vue"),
    //       meta: {
    //         title: "Why is Everyone Talking About Every Door Direct Mail?",
    //       },
    //     },
    //     {
    //       path: "4",
    //       name: "4",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-4" */ "../views/Blogs/4.vue"),
    //       meta: {
    //         title: "Why Every Door Direct Mail is Perfect for These 5 Users",
    //       },
    //     },
    //     {
    //       path: "5",
    //       name: "5",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-5" */ "../views/Blogs/5.vue"),
    //       meta: {
    //         title: "Why 5 Miles is a Magic Number for Local Businesses",
    //       },
    //     },
    //     {
    //       path: "6",
    //       name: "6",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-6" */ "../views/Blogs/6.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Spa & Resort | 12x15",
    //       },
    //     },
    //     {
    //       path: "7",
    //       name: "7",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-7" */ "../views/Blogs/7.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Veterinary | 12x15",
    //       },
    //     },
    //     {
    //       path: "8",
    //       name: "8",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-8" */ "../views/Blogs/8.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Real Estate | 12x15",
    //       },
    //     },
    //     {
    //       path: "9",
    //       name: "9",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-9" */ "../views/Blogs/9.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Gym/Fitness | 12x15",
    //       },
    //     },
    //     {
    //       path: "10",
    //       name: "10",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-10" */ "../views/Blogs/10.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Church | 6x11",
    //       },
    //     },
    //     {
    //       path: "11",
    //       name: "11",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-11" */ "../views/Blogs/11.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Dry Cleaners | 4.25x11",
    //       },
    //     },
    //     {
    //       path: "12",
    //       name: "12",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-12" */ "../views/Blogs/12.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Dentistry | 6x11",
    //       },
    //     },
    //     {
    //       path: "13",
    //       name: "13",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-13" */ "../views/Blogs/13.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Restaurant | 6x11",
    //       },
    //     },
    //     // {
    //     //   path: "14",
    //     //   name: "14",
    //     //   component: () =>
    //     //     import(/* webpackChunkName: "blog-14" */ "../views/Blogs/14.vue"),
    //     //   meta: {
    //     //     title: "Email v/s EDDM",
    //     //   },
    //     // },
    //     {
    //       path: "15",
    //       name: "15",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-15" */ "../views/Blogs/15.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Marketing/Advertising | 6.29x9",
    //       },
    //     },
    //     // {
    //     //   path: "16",
    //     //   name: "16",
    //     //   component: () =>
    //     //     import(/* webpackChunkName: "blog-16" */ "../views/Blogs/16.vue"),
    //     //   meta: {
    //     //     title: "Choosing the Right Design - Law/Legal | 6x11",
    //     //   },
    //     // },
    //     // {
    //     //   path: "17",
    //     //   name: "17",
    //     //   component: () =>
    //     //     import(/* webpackChunkName: "blog-17" */ "../views/Blogs/17.vue"),
    //     //   meta: {
    //     //     title: "Choosing the Right Design - Swimming Pool Cleaning | 6x11",
    //     //   },
    //     // },
    //     // {
    //     //   path: "*",
    //     //   name: "Product",
    //     //   component: () =>
    //     //     import(/* webpackChunkName: "blog-product" */ "../views/Blogs/Product.vue"),
    //     // },
    //     {
    //       path: "18",
    //       name: "18",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-18" */ "../views/Blogs/18.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Florist Shop | 12x15",
    //       },
    //     },
    //     // },
    //     {
    //       path: "19",
    //       name: "19",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-19" */ "../views/Blogs/19.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - Lawn Maintenance | 11x17",
    //       },
    //     },
    //     // },
    //     {
    //       path: "20",
    //       name: "20",
    //       component: () =>
    //         import(/* webpackChunkName: "blog-20" */ "../views/Blogs/20.vue"),
    //       meta: {
    //         title: "Choosing the Right Design - HVAC | 12x15",
    //       },
    //     },
    //   ],
    // },
    {
      path: "/by-audience",
      name: "Response Analytics",
      component: () =>
        import(
          /* webpackChunkName: "response-analytics" */ "../views/ResponseAnalytics.vue"
        ),
      meta: {
        auth: true,
        title: "Response Analytics",
      },
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      meta: {
        auth: false,
        title: "Reset Password",
      },
      component: () =>
        import(
          /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
        ),
    },
    {
      path: "/activate-user",
      name: "Verify Email Address",
      meta: {
        auth: false,
        title: "Verify Email Address",
        sitemap: {
          ignoreRoute: true
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "activate-user" */ "../views/ActivateUser.vue"
        ),
    },
    {
      path: "/verification-pending",
      name: "Email Verification Pending",
      meta: {
        pendingVerification: true,
        title: "Email Verification Pending",
        sitemap: {
          ignoreRoute: true
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "verification-pending" */ "../views/VerificationPending.vue"
        )
    },
    // {
    //   path: "/how-it-works",
    //   name: "How It Works",
    //   meta: {
    //     title: "How It Works",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "how-it-works2" */ "../views/HowItWorks2.vue"),
    // },
    // {
    //   path: "/faq",
    //   name: "Frequently Asked Questions",
    //   meta: {
    //     title: "Frequently Asked Questions",
    //     richText: [rich_text_types.faq]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "faq" */ "../views/FAQ.vue"),
    // },
    // {
    //   path: "/whitepaper",
    //   name: "EDDM Whitepaper",
    //   meta: {
    //     title: "EDDM® Whitepaper",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "whitepaper" */ "../views/Whitepaper.vue"),
    // },
    // {
    //   path: "/how-eddm-works",
    //   name: "How EDDM Works",
    //   meta: {
    //     title: "How EDDM® Works",
    //     appClasses: ["header-yellow"]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "how-eddm-works2" */ "../views/HowEddmWorks2.vue"),
    // },
    // {
    //   path: "/who-we-are",
    //   name: "Who We Are",
    //   meta: {
    //     title: "Who We Are",
    //     appClasses: ["header-yellow"]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "who-we-are2" */ "../views/WhoWeAre2.vue"),
    // },
    // {
    //   path: "/who-is-it-for",
    //   name: "Who Geofactor Is Engineered For",
    //   meta: {
    //     title: "Who Geofactor Is Engineered For",
    //     appClasses: ["header-yellow"]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "who-is-it-for2" */ "../views/WhoIsItFor2.vue"),
    // },
    // {
    //   path: "/other-marketing-methods",
    //   name: "EDDM vs. Other Marketing Methods",
    //   meta: {
    //     title: "EDDM® vs. Other Marketing Methods",
    //     appClasses: ["header-blue"],
    //     sitemap: {
    //       ignoreRoute: true
    //     }
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "other-marketing-methods2" */ "../views/OtherMarketingMethods2.vue"),
    // },
    // {
    //   path: "/comparison",
    //   name: "Why We’re Better Than the Competition",
    //   meta: {
    //     title: "Why We’re Better Than the Competition",
    //     appClasses: ["header-green"]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "comparison2" */ "../views/Comparison2.vue"),
    // },
    // {
    //   path: "/case-studies",
    //   name: "Case Studies",
    //   meta: {
    //     title: "Case Studies",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "case-studies" */ "../views/CaseStudies.vue"),
    // },
    // {
    //   path: "/testimonials",
    //   name: "Testimonials",
    //   meta: {
    //     title: "Testimonials",
    //     appClasses: ["header-twilight-blue"]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "testimonials" */ "../views/Testimonials.vue"),
    // },
    {
      path: "/pricing",
      name: "Elevate Your Marketing",
      meta: {
        title: "Elevate Your Marketing",
        appClasses: ["header-twilight-blue"]
      },
      component: () =>
        import(/* webpackChunkName: "pricing" */ "../views/Pricing.vue"),
    },
    {
      path: "/tiers",
      name: "Tiers",
      meta: {
        auth: false,
        title: "Choose a tier to start",
        appClasses: ["header-blue"],
        // sitemap: {
        //   ignoreRoute: true
        // }
      },
      component: () =>
        import(/* webpackChunkName: "tiers" */ "../views/Tiers.vue"),
    },
    // {
    //   path: "/contact-us",
    //   name: "Contact Us",
    //   meta: {
    //     title: "Contact Us",
    //     appClasses: ["header-red"]
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "contact-us2" */ "../views/ContactUs2.vue"),
    // },
    // {
    //   path: "/portal",
    //   name: "Landing Page",
    //   meta: {
    //     title: "Smart EDDM® Campaigns",
    //     hideHeaderLinks: true
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "landing-page" */ "../views/LandingPage.vue"),
    // },
    // {
    //   path: "/portal-audience",
    //   name: "Landing Page Audience",
    //   meta: {
    //     title: "Smart EDDM® Campaigns",
    //     hideHeaderLinks: true
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "landing-page" */ "../views/LandingPage.vue"),
    //   props: {
    //     custom: 'audience'
    //   }
    // },
    // {
    //   path: "/portal-creative",
    //   name: "Landing Page Creative",
    //   meta: {
    //     title: "Smart EDDM® Campaigns",
    //     hideHeaderLinks: true
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "landing-page" */ "../views/LandingPage.vue"),
    //   props: {
    //     custom: 'creative'
    //   }
    // },
    // {
    //   path: "/offer",
    //   name: "Landing Page Coupon",
    //   meta: {
    //     title: "Smart EDDM® Campaigns",
    //     hideHeaderLinks: true,
    //     // appClasses: ['header-highlight'],
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "landing-coupon" */ "../views/LandingCoupon.vue"),
    // },
    // {
    //   path: "/terms",
    //   name: "Terms and Conditions",
    //   meta: {
    //     title: "Terms and Conditions",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "terms" */ "../views/Terms.vue"),
    // },
    // {
    //   path: "/privacy",
    //   name: "Privacy Policy",
    //   meta: {
    //     title: "Privacy Policy",
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
    // },
    // {
    //   path: "/coming-soon",
    //   name: "Coming Soon",
    //   meta: {
    //     title: "Coming Soon",
    //     sitemap: {
    //       ignoreRoute: true
    //     }
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "coming-soon" */ "../views/ComingSoon.vue"),
    // },
    {
      path: "/qr-preview",
      name: "QR Preview",
      meta: {
        title: "Your QR Code works!",
        sitemap: {
          ignoreRoute: true
        }
      },
      component: () =>
        import(/* webpackChunkName: "qr-preview" */ "../views/QrPreview.vue"),
    },
    {
      path: '/dashboard',
      meta: {
        auth: true,
        sitemap: {
          ignoreRoute: true,
        },
        title: 'Dashboard',
      },
      component: () => import(/* webpackChunkName: "dashboard" */ "../views/ExecutiveDashboard.vue")
    },
    {
      path: '/campaigns',
      meta: {
        auth: true,
        sitemap: {
          ignoreRoute: true,
        },
      },
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '',
          name: 'Campaigns',
          meta: {
            title: 'Campaigns',
            reloadOnLogin: true,
          },
          component: () => import(/* webpackChunkName: "campaigns-list" */ "../views/ListCampaigns.vue")
        },
        {
          path: 'create-direct-mail-campaign',
          name: 'NewDirectMailCampaign',
          meta: {
            title: 'Create a new campaign',
          },
          component: () => import(/* webpackChunkName: "campaigns-dm" */ "../views/NewDirectMailCampaign.vue")
        },
        {
          path: 'configure-informed-delivery',
          name: 'ConfigureInformedDelivery',
          meta: {
            title: 'Configure Informed Delivery',
          },
          component: () => import(/* webpackChunkName: "configure-id" */ "../views/ConfigureInformedDelivery.vue")
        },
        {
          path: 'edit',
          name: 'EditDirectMailCampaign',
          meta: {
            title: 'Edit Campaign',
            reloadOnLogin: true,
          },
          component: () => import(/* webpackChunkName: "campaigns-dm" */ "../views/NewDirectMailCampaign.vue"),
          props: {
            isEdit: true,
          },
        },
        {
          path: "proof",
          name: "Proof Campaign",
          meta: {
            auth: true,
            side: false,
            title: "Proof Campaign",
          },
          component: () => import(/* webpackChunkName: "proof-campaign" */ "../views/modules/DirectMail/ProofCampaign.vue"),
        },
        {
          path: 'informed-delivery',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'InformedDelivery',
              meta: {
                title: 'Informed Delivery',
                reloadOnLogin: true,
              },
              component: () => import(/* webpackChunkName: "id-list" */ "../views/ListInformedDelivery.vue")
            },
            {
              path: 'create-informed-delivery',
              name: 'CreateInformedDelivery',
              meta: {
                title: 'Create Informed Delivery',
              },
              component: () => import(/* webpackChunkName: "create-id" */ "../views/ConfigureInformedDelivery.vue"),
              props: {
                isStandalone: true,
              },
            },
            {
              path: 'edit-informed-delivery',
              name: 'EditInformedDelivery',
              meta: {
                title: 'Create Informed Delivery',
              },
              component: () => import(/* webpackChunkName: "create-id" */ "../views/ConfigureInformedDelivery.vue"),
              props: {
                isStandalone: true,
              },
            },
            {
              path: ':id',
              name: 'Informed Delivery Details',
              meta: {
                title: 'Informed Delivery Details',
              },
              component: () => import(/* webpackCunkName: "id-details" */ "../views/InformedDeliveryDetails.vue"),
            },
          ],
        },
        {
          path: ':id',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'Campaign Details',
              meta: {
                title: 'Campaign Details',
              },
              component: () => import(/* webpackCunkName: "campaign-details" */ "../views/CampaignDetails.vue"),
            },
            // {
            //   path: '',
            //   meta: {
            //     title: 'Campaign Details',
            //   },
            //   component: () => import(/* webpackCunkName: "campaign-details" */ "../views/CampaignDetails.vue"),
            // },
            {
              path: 'tracking/:type',
              name: 'Campaign Tracking Details',
              meta: {
                title: 'Campaign Tracking Details',
              },
              component: () => import(/* webpackCunkName: "campaign-details" */ "../views/CampaignTrackingDetails.vue"),
            },
          ],
        }
      ],
    },
    {
      path: '/eddm-campaigns',
      meta: {
        auth: true,
        sitemap: {
          ignoreRoute: true,
        },
      },
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '',
          name: 'EDDM Campaigns',
          meta: {
            title: 'EDDM Campaigns',
            reloadOnLogin: true,
          },
          component: () => import(/* webpackChunkName: "eddm-campaigns-list" */ "../views/ListEDDMCampaigns.vue")
        },
        {
          path: 'create-eddm-campaign',
          name: 'NewEDDMCampaign',
          meta: {
            title: 'Create a new campaign',
          },
          component: () => import(/* webpackChunkName: "campaigns-eddm" */ "../views/AudienceGuided.vue")
        },
        // {
        //   path: 'edit',
        //   name: 'EditDirectMailCampaign',
        //   meta: {
        //     title: 'Edit Campaign',
        //     reloadOnLogin: true,
        //   },
        //   component: () => import(/* webpackChunkName: "campaigns-eddm" */ "../views/AudienceGuided.vue"),
        //   props: {
        //     isEdit: true,
        //   },
        // },
        // {
        //   path: "proof",
        //   name: "Proof Campaign",
        //   meta: {
        //     auth: true,
        //     side: false,
        //     title: "Proof Campaign",
        //   },
        //   component: () => import(/* webpackChunkName: "proof-campaign" */ "../views/modules/DirectMail/ProofCampaign.vue"),
        // },
        {
          path: ':id',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: 'EDDM Campaign Details',
              meta: {
                title: 'EDDM Campaign Details',
              },
              component: () => import(/* webpackCunkName: "campaign-details" */ "../views/CampaignDetails.vue"),
            },
            {
              meta: {
                title: 'EDDM Campaign Details',
              },
              path: 'distribution/:distribution',
              component: () => import(/* webpackCunkName: "campaign-details" */ "../views/CampaignDetails.vue"),
            },
          ],
        }
      ],
    },
    {
      path: "/dm",
      meta: {
        auth: true,
        sitemap: {
          ignoreRoute: true
        }
      },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "",
          redirect: "/campaigns"
        },
        {
          path: "new",
          redirect: "/campaigns/create-direct-mail-campaign"
        },
        {
          path: ":id?",
          redirect: to => {
            if (to.query.cart) {
              return '/campaigns/' + to.query.cart;
            }
            return '/campaigns';
          }
        },
      ],
    },
    {
      path: "/predictions",
      meta: {
        auth: true,
      },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "",
          name: "Predictions",
          meta: {
            title: "Predictions Studio",
          },
          component: () => import(/* webpackChunkName: "predictions-lookalikes" */ "../views/modules/CustomerDataManager/ListLookalikes.vue"),
        },
        {
          path: "lookalikes",
          redirect: "/predictions",
        },
        {
          path: "new-prediction-model/:id",
          name: "Build Model",
          meta: {
            title: "Build prediction model",
            sitemap: {
              slugs: ALL_PREDICTION_MODEL_TYPES.filter(t => !t.other).map(t => t.value),
            }
          },
          component: () => import(/* webpackChunkName: "new-prediction-model" */ "../views/modules/CustomerDataManager/NewPredictionModel.vue"),
        },
        {
          path: "new-lookalike-model",
          redirect: '/predictions/new-prediction-model/lookalike',
        },
        {
          path: "other",
          name: "Build Custom Model",
          meta: {
            title: "Build custom model",
          },
          component: () => import(/* webpackChunkName: "build-custom-model" */ "../views/modules/CustomerDataManager/BuildCustomModel.vue"),
        },
        {
          path: ':id',
          name: 'Prediction model details',
          meta: {
            title: 'Prediction model details',
            sitemap: {
              ignoreRoute: true,
            },
          },
          component: () => import(/* webpackChunkName: "prediction-details" */ "../views/modules/CustomerDataManager/PredictionModel.vue"),
        },
      ],
    },
    {
      path: "/cdm",
      meta: {
        auth: true,
      },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "",
          name: "Audiences",
          meta: {
            title: "Audiences",
            reloadOnLogin: true,
          },
          component: () => import(/* webpackChunkName: "cdm" */ "../views/modules/CustomerDataManager/CustomerDataManager.vue"),
        },
        {
          path: "lookalikes",
          redirect: '/predictions/lookalikes',
        },
        // {
        //   path: "new",
        //   name: "New Audience",
        //   meta: {
        //     title: "New Audience",
        //   },
        //   component: () => import(/* webpackChunkName: "new-audience" */ "../views/modules/CustomerDataManager/NewAudience.vue"),
        // },
        // {
        //   path: "import",
        //   name: "Import Audience from CRM",
        //   meta: {
        //     title: "Import Audience from CRM",
        //   },
        //   component: () => import(/* webpackChunkName: "import-audience" */ "../views/modules/CustomerDataManager/ImportAudience.vue"),
        // },
        // {
        //   path: "upload",
        //   name: "Upload Audience List",
        //   meta: {
        //     title: "Upload Audience List",
        //   },
        //   component: () => import(/* webpackChunkName: "upload-audience" */ "../views/modules/CustomerDataManager/UploadAudience.vue"),
        // },
        // {
        //   path: "build",
        //   name: "Build Audience",
        //   meta: {
        //     title: "Build Audience",
        //   },
        //   component: () => import(/* webpackChunkName: "build-audience" */ "../views/modules/CustomerDataManager/BuildAudience.vue"),
        // },
        {
          path: "lookalike",
          redirect: '/predictions/new-lookalike-model'
        },
        {
          path: "build-audience",
          name: "CDM - Build Auidence",
          meta: {
            title: "Build Audience"
          },
          component: () => import(/* webpackChunkName: "build-audience" */ "../views/modules/CustomerDataManager/BuildAudience/BuildAudience.vue"),
        },
        {
          path: "upload-audience",
          name: "CDM - Upload Auidence",
          meta: {
            title: "Upload Audience"
          },
          component: () => import(/* webpackChunkName: "upload-audience" */ "../views/modules/CustomerDataManager/BuildAudience/UploadAudience.vue"),
        },
        {
          path: "visualize",
          name: "Data Visualizer",
          meta: {
            auth: true,
            title: "Data Visualizer",
          },
          component: () =>
            import(/* webpackChunkName: "visualize" */ "../views/DataVisualizer.vue"),
        },
      ]
    },
    {
      path: "/creatives",
      meta: {
        auth: true,
      },
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "",
          name: "Creatives",
          meta: {
            title: "Creatives",
            reloadOnLogin: true,
          },
          component: () => import(/* webpackChunkName: "creatives" */ "../views/Account/SavedDesigns.vue"),
        },
        {
          path: "build",
          name: "Build Creative",
          meta: {
            title: "Build Creative"
          },
          component: () => import(/* webpackChunkName: "build-creative" */ "../views/Creatives/BuildCreative.vue"),
        },
        {
          path: "upload",
          name: "Upload Creative",
          meta: {
            title: "Upload Creative"
          },
          component: () => import(/* webpackChunkName: "upload-audience" */ "../views/Creatives/UploadCreative.vue"),
        },
      ]
    },
    {
      path: "*",
      name: "Not Found",
      meta: {
        title: "404 | Not Found",
      },
      component: () =>
        import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
    },
  ];
}

async function getAllProductIds() {
  return (await axios.get(`${process.env.VUE_APP_API_URL}/api/products`, { params: { limit: 1000 } })).data.map(p => p.id);
}
function isPortrait(el, value) {
  el.classList.remove("landscape");
  el.classList.remove("portrait");
  if (value) {
    el.classList.add(value.naturalHeight > value.naturalWidth ? "portrait" : "landscape");
  }
}

const updateClass = (el, value) => {
  if (value instanceof HTMLImageElement) {
    value.onload = () => {
      isPortrait(el, value);
    };
    value.onerror = () => {
      isPortrait(el, value);
    }
  }
  isPortrait(el, value);
};

export const ImgOrientationDirective = {
  bind(el, binding) {
    updateClass(el, binding.value);
  },
  componentUpdated(el, binding) {
    updateClass(el, binding.value);
  },
};

import { axios } from "../axios";
import { inIframe, postMessageToParent } from "../utils";
import { EventTypes } from "../event-bus";
import Cookies from "js-cookie";

class AuthService {
  constructor() {
    this.apiEndpoint = process.env.VUE_APP_API_URL + "/api";
    this.isAuthenticated = false;
    this.verificationPending = false;
    this.userId = null;
    this.token = null;
    this.userData = null;
    this.isDemo = false;

    const existingCartId = localStorage.getItem("cartId");

    this.loading = this.loadStorage().then(() => {
      if (existingCartId) {
        const data = {
          cartID: existingCartId,
          userID: this.userId,
        };
        if (this.token) {
          data.session_token = this.token;
        }
        return axios
        .post(this.apiEndpoint + "/validateCart", data);
      } else {
        return Promise.resolve(false);
      }
    }).then(() => {
      console.log('Cart is valid');
    }).catch(err => {
      console.log(err);
    });
  }

  handleLogin(data, isTemporary = false) {
    if (!isTemporary && data.verified) {
      if (data.loggedIn) {
        localStorage.setItem("token", data.session_token);
        localStorage.setItem("cartId", data.cartID);
        localStorage.setItem("userId", data.userID);
        localStorage.removeItem("verificationPending");
        localStorage.removeItem("pending_userId");
        localStorage.removeItem("pending_token");
      }

      return this.loadStorage();
    } else {
      this.token = data.session_token;
      this.userId = data.userID;
      if (isTemporary) {
        localStorage.setItem("cartId", data.cartID);
        localStorage.setItem("userId", data.userID);
        return Promise.resolve(true);
      } else {
        // this.isAuthenticated = !!this.token && !!this.userId;
        localStorage.setItem("verificationPending", true);
        localStorage.setItem("pending_userId", this.userId);
        localStorage.setItem("pending_token", this.token);
        this.isAuthenticated = false;
        this.verificationPending = true;
        if (!data.bridge) {
          this.loadTemporaryUser();
        }

        return Promise.resolve(this.verificationPending);
      }
    }
  }

  loadStorage() {
    if (localStorage.getItem("verificationPending")) {
      this.token = localStorage.getItem("token");
      this.userId = localStorage.getItem("userId");
      // this.token = localStorage.getItem("pending_token");
      // this.userId = localStorage.getItem("pending_userId");
      this.isAuthenticated = false;
      this.verificationPending = true;
      return Promise.resolve(false);
    } else {
      this.token = localStorage.getItem("token");
      this.userId = localStorage.getItem("userId");
      this.isAuthenticated = !!this.token && !!this.userId;
      this.verificationPending = false;

      if (this.isAuthenticated) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'login',
          user: {
            id: this.userId
          }
        });
        return this.fetchUserDetails();
      } else if (!this.userId) {
        return this.loadTemporaryUser();
      } else {
        return Promise.resolve(false);
      }
    }
  }

  fetchUserDetails() {
    if (this.isAuthenticated) {
      return axios
        .post(this.apiEndpoint + "/getUserDetails", {
          userID: this.userId,
          session_token: this.token,
        })
        .then((data) => {
          this.userData = data.data;
          if (this.isAuthenticated) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'user_details_updated',
              user: {
                id: this.userId,
                email: data.data.email,
                name: data.data.firstname + (data.data.lastname ? ` ${data.data.lastname}` : ''),
                phone: data.data.phone,
                // companyName: data.data.company,
                // companyIndustry: data.data.industry
              }
            });
          }
          this.isDemo = !!(this.userData && this.userData.demo);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return Promise.reject();
    }
  }

  loadTemporaryUser() {
    return axios
      .get(this.apiEndpoint + "/getAnonUserID")
      .then((data) => this.handleLogin(data.data, true))
      .catch((err) => console.log(err));
  }

  logout(force) {
    axios
      .post(this.apiEndpoint + "/logout", {
        userID: this.userId,
        session_token: this.token,
      })
      .then((data) => {
        if (data.status == 200) {
          this.clearStorage();
        }
      })
      .catch((err) => {
        console.log(err);
        if (force) {
          this.clearStorage();
        }
      });
  }

  clearStorage() {
    Object.keys(Cookies.get()).forEach(function(cookieName) {
      if (cookieName != "acceptCookies") {
        Cookies.remove(cookieName);
      }
    });
    localStorage.clear();
    sessionStorage.clear();
    if (inIframe()) {
      postMessageToParent({ type: EventTypes.IFRAME_LOGOUT, data: '/' });
    } else {
      location.href = "/";
    }
  }
}

export const authService = new AuthService();

<template>
  <component :is="tag" ref="root" class="lazy" :style="{'--lazy-background-color': placeholder}">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: "LazyBg",
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    placeholder: {
      type: String,
      default: 'transparent'
    }
  },
  mounted() {
    {
      if (
        "IntersectionObserver" in window &&
        "IntersectionObserverEntry" in window &&
        "intersectionRatio" in window.IntersectionObserverEntry.prototype
      ) {
        this.$nextTick(() => {
          this.intersectionObserver = new IntersectionObserver(
            (entries, observer) => {
              if (entries[0].intersectionRatio > 0) {
                this.showEvent();
                observer.unobserve(this.$refs.root);
              }
            }
          );
          this.intersectionObserver.observe(this.$refs.root);
        });
      } else if (this.$refs.root) {
        this.showEvent();
      }
    }
  },
  beforeDestroy() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  },
  data() {
    return {
      intersectionObserver: null,
    };
  },
  methods: {
    showEvent() {
      this.$refs.root.classList.remove("lazy");
      this.$refs.root.classList.add("visible");
      this.$emit("show", { target: this.$refs.root });
    }
  }
};
</script>
<style scoped>
.lazy {
  background-image: none !important;
  background-color: var(--lazy-background-color) !important;
}
</style>
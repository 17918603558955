import { googleService } from "../services/google.service";

// function getAddressObject(place) {
//   let addressComponent;
//   let addressComponents = place.address_components;
//   const address = {
//     cityName: "",
//     stateCode: "",
//     stateName: "",
//     countryCode: "",
//     countryName: "",
//   };

//   //get city
//   addressComponent = getAddressComponentValue(addressComponents, "locality");
//   if (addressComponent) {
//     address.cityName = addressComponent;
//   } else {
//     addressComponent = getAddressComponentValue(
//       addressComponents,
//       "administrative_area_level_2"
//     );
//     if (addressComponent) {
//       address.cityName = addressComponent;
//     }
//   }

//   addressComponent = getAddressComponent(
//     addressComponents,
//     "administrative_area_level_1"
//   );
//   if (addressComponent) {
//     address.stateCode = addressComponent["short_name"];
//     address.stateName = addressComponent["long_name"];
//   }

//   //set country
//   addressComponent = getAddressComponent(addressComponents, "country");
//   if (addressComponent) {
//     address.countryCode = addressComponent["short_name"];
//     address.countryName = addressComponent["long_name"];
//   }

//   return Object.values(address).some((value) => !!value) ? address : null;
// }

// function getAddressComponent(addressComponents, name) {
//   return (
//     addressComponents &&
//     addressComponents.filter((address) => address.types.indexOf(name) !== -1)[0]
//   );
// }

// function getAddressComponentValue(addressComponents, name, isLongName = true) {
//   let addressComponent = null;
//   let component = getAddressComponent(addressComponents, name);
//   if (component)
//     addressComponent = component[isLongName ? "long_name" : "short_name"];
//   return addressComponent;
// }

// function setPlaceObjectFromString(el, value) {
//     const keys = ['cityName', 'stateName', 'countryName'];
//     let selectedAddress = {};
//     if (value && typeof(value) === 'string') {
//       const split = value.split(',').map(item => item.trim());
//       const diff = split.length < keys.length ? keys.length - split.length : 0;
//       keys.forEach((key, i) => selectedAddress[key] = split[i + diff] || '');
//       el.value = value;
//     } else if (!value || keys.every(key => value.hasOwnProperty(key))) {
//       selectedAddress = value;
//       el.value = value ? keys.map(key => value[key]).filter(value => !!value).join(', ') : '';
//     }
// }

function invokeEvent(place, vnode) {
  // const selectedAddress = place && getAddressObject(place);
  const name = "input";
  if (vnode) {
    const handlers =
      (vnode.data && vnode.data.on) ||
      (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
      if (place && place.formatted_address) {
        vnode.elm.value = place.formatted_address;
      }
      handlers[name]({ target: vnode.elm, value: vnode.elm.value, address: place });
    }
    // vnode.context.$emit("selected", selectedAddress);
  }
}

export const GooglePlaceDirective = {
  bind(el, binding, vnode) {
    googleService.loadMaps().then(() => {
      let autoCompleteOptions = { types: ["geocode"] };
      if (binding.value) {
        const paramType = typeof(binding.value);
        if (paramType == "string") {
          autoCompleteOptions.types = [binding.value];
        } else if (paramType == "object") {
          Object.keys(binding.value).forEach(k => autoCompleteOptions[k] = binding.value[k]);
        }
      }

      const autocomplete = new window.google.maps.places.Autocomplete(
        el,
        autoCompleteOptions
      );
      window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          const place = autocomplete.getPlace();
          invokeEvent(place, vnode);
        }
      );
    });
    // el.addEventListener('input', event => el.value = JSON.parse(JSON.stringify(event.target.value)));
  },
};
